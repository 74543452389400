import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputs = ({error, ...rest}) => {
    return (
        <>
            <PhoneInput
                {...rest}
            />
            {error && <div>{error}</div>}
        </>
    )
}

export default PhoneInputs;