import React from 'react';
import { useHistory } from 'react-router-dom';
import countries from './listCountries';
import Verified from '../../../assets/images/icon/tick.svg';
import SendMsg from '../../../assets/images/icon/sendMsg.png';
import smsImage from '../../../assets/images/banner/Login-sms.png';
import smsRegister from '../../../assets/images/banner/register_sms.png';
import smsMobile from '../../../assets/images/banner/register_mobile.png';
import {
	GetListing,
	filter,
	getBusiness,
	sendSMS,
} from '../../../services/businessService';
import TextareaAutosize from 'react-textarea-autosize';
import axios from 'axios';
import { Modal, Row, Col } from 'react-bootstrap';

import { lang } from '../../../utils/trans';

import { GetListing as GetBusinessInfoAdmin } from '../../../services/listing';

import FilterComponent from './filterComponent';
import Layout from './layout';

import './index.css';
import { getCurrentUser } from '../../../services/authService';
import http from '../../../services/httpService';

export default class ViewCompanyView extends React.Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			country: this.props.match.params.country,
			id: this.props.match.params.slug,
			listing: {},
			loading: true,
			errorName: false,
			errorMsg: false,
			error: '',
			usage: '',
			optionActive: { value: '', label: lang('Who, What ?') },
			countryActive: { value: '', label: lang('Where') },
			success: false,
			message: {
				business: '',
				text: '',
			},

			smsShow: true,
		};
	}

	// handleChange = ({ target: input }) => {
	// 	const sender = { ...this.state.sender };
	// 	if (input.name == 'user') {
	// 		if (input.value.length > 11) return this.setState({ errorName: true });
	// 		sender[input.name] = input.value;
	// 	}

	// 	if (input.name == 'message') {
	// 		if (input.value.length > 140) return this.setState({ errorMsg: true });
	// 		sender[input.name] = input.value;
	// 	}

	// 	this.setState({ sender });
	// };

	async componentDidMount() {
		console.log(getCurrentUser());
		this.setState({ loading: true });
		window.scrollTo(0, 0);

		if (localStorage.getItem('token')) {
			const business = await getBusiness();

			this.setState({
				message: {
					...this.state.message,
					business: business.data.data?.name.replace(/\s+/g, '-').slice(0, 11),
				},
			});
		}

		const listing = await GetListing(this.state.id);
		this.setState({
			loading: false,
			listing: listing.data.data,
		});
	}

	handleSMSChange = e => {
		this.setState({ errorName: false, errorMsg: false });

		if (e.target.name === 'business') {
			if (e.target.value.length > 11) {
				return this.setState({ errorName: true });
			}
		}

		if (e.target.name === 'text') {
			if (e.target.value.length > 160) {
				return this.setState({ errorMsg: true });
			}
		}

		this.setState({
			message: { ...this.state.message, [e.target.name]: e.target.value },
		});
	};

	handleSubmit = async e => {
		e.preventDefault();

		try {
			this.setState({ loading: true });

			const response = await http.post(
				process.env.REACT_APP_AFRICA_ONLINE_API + '/business/sms',
				{
					receiver_id: this.state.listing.id,
					sid: this.state.message?.business,
					phone:
						this.state.listing.phone.length > 0
							? this.state.listing.phone[0]
							: null,
					message: this.state.message?.text,
				}
			);

			this.setState({ usage: response.data.data.usage, success: true });

			setTimeout(() => {
				this.setState({
					message: { ...this.state.message, text: '' },
					loading: true,
				});

				window.location.reload();
			}, 5000);
		} catch (error) {
			this.setState({ loading: false });

			if (error.response && error.response.status == 403) {
				this.setState({ error: error.response.data.detail });
			}
		}
	};

	render() {
		const user = getCurrentUser();

		return (
			<Layout country={this.state.country} theme={true}>
				<FilterComponent
					country={this.state.country}
					optionActive={this.state.optionActive}
					countryActive={this.state.countryActive}
				/>

				<section className=' bg-white py-5'>
					<div className='container'>
						<div className='clearfix'>
							<div className='float-left'>
								<h6 className='text-muted'>
									<small>{this.state.listing.sector}</small>{' '}
								</h6>
								<h4 className='text-primary font-weight-bold'>
									{' '}
									{this.state.listing.name}{' '}
									{this.state.listing.verified === 2 && (
										<img src={Verified} width='25' />
									)}
								</h4>
								<small className='text-muted pb-4'>
									{' '}
									{this.state.listing.address}
								</small>
							</div>
							<div className='float-right mt-5'>
								<a
									className='btn py-2 px-3'
									style={{
										backgroundColor: '#6716f9',
										color: '#fff',
										borderRadius: 0,
									}}
									href='#smsModal'
									data-toggle='modal'
								>
									<img src={SendMsg} /> &nbsp; <small>Direct SMS</small>
								</a>
							</div>
						</div>

						<hr />
						<div className='row'>
							<div className='col-md-6 order-md-last'>
								<div className='card shadow border-0 my-2'>
									<div className='card-body pb-1'>
										<div className='clearfix text-muted'>
											<div className='float-left'>
												<div className='media text-muted'>
													<i className='fa fa-mobile mt-1'></i>
													<div className='media-body pl-2'>
														<p className='font-weight-bold'>Phone / Mobile</p>
													</div>
												</div>
											</div>
											<div className='float-right'>
												<div>
													{this.state.loading
														? 'Loading...'
														: Object.keys(this.state.listing.phone).map(
																(key, index) =>
																	this.state.listing.phone[key] === 'N/A' ? (
																		''
																	) : (
																		<p key={index} className='font-weight-bold'>
																			<a
																				href={
																					'tel:' + this.state.listing.phone[key]
																				}
																				className='mr-2 text-muted'
																			>
																				{this.state.listing.phone[key]}
																			</a>
																		</p>
																	)
														  )}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='card shadow border-0 my-3'>
									<div className='card-body pb-1'>
										<div className='clearfix text-muted'>
											<div className='float-left'>
												<div className='media text-muted'>
													<i className='fa fa-clock mt-1'></i>
													<div className='media-body pl-2'>
														<p className='font-weight-bold'>Open</p>
													</div>
												</div>
											</div>
											<div className='float-right'>
												<p>Open 24/7</p>
											</div>
										</div>
									</div>
								</div>
								<div className='card shadow border-0 my-3'>
									<div className='card-body pb-1'>
										<div className='clearfix text-muted'>
											<div className='float-left'>
												<div className='media text-muted'>
													<i className='fa fa-tasks mt-1'></i>
													<div className='media-body pl-2'>
														<p className='font-weight-bold'>Category</p>
													</div>
												</div>
											</div>
											<div className='float-right'>
												<p>{this.state.listing.sector}</p>
											</div>
										</div>
									</div>
								</div>
								{/* <div className='card shadow border-0 my-3'>
									<div className='card-body pb-1'>
										<div className='clearfix text-muted'>
											<div className='float-left'>
												<div className='media text-muted'>
													<i className='fa fa-envelope mt-1'></i>
													<div className='media-body pl-2'>
														<p className='font-weight-bold'>Email</p>
													</div>
												</div>
											</div>
											<div className='float-right'>
												<p>your-email@example.com</p>
											</div>
										</div>
									</div>
								</div> */}
								<div className='card shadow border-0 my-3'>
									<div className='card-body pb-1'>
										<div className='clearfix text-muted'>
											<div className='float-left'>
												<div className='media text-muted'>
													<i className='fa fa-map-marker mt-1'></i>
													<div className='media-body pl-2'>
														<p className='font-weight-bold'>Location</p>
													</div>
												</div>
											</div>
											<div className='float-right'>
												<p>{this.state.listing.address}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='card shadow border-0 my-2'>
									<div className='card-body'>
										<div className='media text-muted'>
											<i className='fa fa-bars mt-1'></i>
											<div className='media-body pl-2'>
												<p className='font-weight-bold mb-3'>Description</p>
											</div>
										</div>
										<p className='text-muted'>
											{this.state.listing.description}
										</p>
									</div>
								</div>
								<div className='card shadow border-0'>
									<div className='card-body pb-1'>
										<div className='media'>
											<div className='media-body pl-2'>
												<p className='font-weight-bold text-muted'>
													Website Link
												</p>
											</div>
										</div>
										<div className='media text-muted mb-3'>
											<i className='fa fa-globe pt-1'></i>
											<div className='media-body pl-2'>
												<a
													href={
														this.state.listing.website?.charAt(0) !== 'h'
															? 'https://' + this.state.listing.website
															: this.state.listing.website
													}
													target='_blank'
													className='text-black-50'
												>
													{this.state.listing.website}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div class='modal fade' id='smsModal'>
					<div class='modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable'>
						<div class='modal-content'>
							<div className='modal-header border-0 py-md-2 py-0'>
								<button
									type='button'
									class='close'
									data-dismiss='modal'
									aria-label='Close'
								>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
							<div
								class={
									!this.state.loading
										? 'modal-body py-md py-0'
										: 'modal-body py-5'
								}
							>
								{!this.state.loading ? (
									<div className='row'>
										<div className='col-md-5 offset-md-1 my-md-0 my-md-3'>
											{user ? (
												<div className='py-0'>
													<img src={smsImage} className='img-fluid' />
													{/* <div
														className='mx-4'
														style={{ backgroundColor: '#e9ecef' }}
													>
														<div
															className='clearfix p-2'
															style={{ backgroundColor: '#fff' }}
														>
															<div className='float-left'>
																<div className='media'>
																	<div className='media-body pl-2'>
																		<p className='text-muted pb-0 mb-0'>
																			MESSAGES
																		</p>
																	</div>
																</div>
															</div>
															<div className='float-right'>
																<small>now</small>
															</div>
														</div>
														<h6 className='mb-0 pb-0 pt-2 pl-2'>
															<small className='font-weight-bold'>
																{this.state.message.business}
															</small>
														</h6>
														<textarea
															rows='4'
															className='form-control'
															value={this.state.message.text}
															readOnly
														></textarea>
													</div> */}
												</div>
											) : (
												<>
													<div className='d-md-block d-none'>
														<img
															src={smsRegister}
															className='img-fluid'
															alt=''
														/>
													</div>
													<div className='d-md-none d-block'>
														<img src={smsMobile} className='img-fluid' alt='' />
													</div>
												</>
											)}
										</div>

										<div
											className={`col-md-6 ${
												!user || (!user && 'text-center')
											} px-5 my-md-0 my-3 order-md-last`}
										>
											<div
												className='d-flex flex-column justify-content-center'
												style={{ height: '100%' }}
											>
												{!user && (
													<>
														<h6>Want to send a message?</h6>
														<div className='card shadow border-0 my-3 bg-light'>
															<div className='card-body py-md py-1'>
																<p className='pb-0 mb-0'>
																	Create an account. add your company <br /> and
																	start promoting your products & <br />{' '}
																	services by sending direct SMS for free.
																</p>
															</div>
														</div>
														<a
															href={
																'/signUp?redirect=true&page=' + this.state.id
															}
															className='btn btn-primary btn-sm px-md-4 py-2 mx-md-5 mt-md-5'
														>
															Create an Account
														</a>
														<h6 className='text my-md-4 my-1'>Or</h6>
														<a
															href={
																'/signIn?redirect=true&page=' + this.state.id
															}
															className='btn btn-success btn-sm px-4 py-2 mx-md-5 mt-3'
														>
															Log in
														</a>
													</>
												)}
												{user && (
													<>
														{this.state.error && (
															<div className='alert alert-danger'>
																{this.state.error}
															</div>
														)}
														<form onSubmit={this.handleSubmit}>
															<div className='form-group'>
																<label className='font-weight-bold'>
																	Company name:{' '}
																</label>
																<input
																	type='text'
																	className='form-control'
																	placeholder='Company name'
																	name='business'
																	value={this.state.message.business}
																	autoComplete='off'
																/>
																{this.state.errorName ? (
																	<p>
																		<small className='font-weight-bold text-danger'>
																			Limit: 11 characters
																		</small>
																	</p>
																) : null}
															</div>
															<div className='form-group'>
																<label className='font-weight-bold'>
																	Message:{' '}
																</label>

																<TextareaAutosize
																	placeholder='Message'
																	className='form-control'
																	name='text'
																	value={this.state.message.text}
																	onChange={this.handleSMSChange}
																	required={true}
																/>

																{this.state.errorMsg ? (
																	<p>
																		<small className='font-weight-bold text-danger'>
																			Limit: 160 characters
																		</small>
																	</p>
																) : null}
															</div>
															<button type='submit' className='btn btn-info'>
																Send SMS
															</button>
														</form>
													</>
												)}
											</div>
										</div>
									</div>
								) : this.state.success ? (
									<div className='row'>
										<div
											className='d-flex flex-column justify-content-center align-items-center'
											style={{ height: '100%', width: '100%' }}
										>
											{this.state.usage && (
												<p className='font-weight-bold text-danger my-4'>
													Remaining {this.state.usage}
												</p>
											)}
											<span className='iconBox'>
												<i className='fas fa-check fa-3x' />
											</span>
											<p className='pt-4'>
												Message successfully sent! They will get back to you.
											</p>
										</div>
									</div>
								) : (
									<div className='row'>
										<div
											className='d-flex flex-column justify-content-center align-items-center'
											style={{ height: '100%', width: '100%' }}
										>
											<h6 className='font-weight-bold'>Loading.....</h6>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}
