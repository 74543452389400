import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { getTransaction } from '../../../../services/emerchant';
import AdminLayout from '../../../layouts/AdminLayout';

class DashboardTransactionView extends Component {
  state = {  
    transaction: [],
    modal: false
  }

  handleModal= (id) => {
    this.setState({modal: !this.state.modal})
    console.log(id);
  }

  async componentDidMount() {
    const response = await getTransaction();
    this.setState({transaction: response.data.data});

    console.log(this.state.transaction);
  }

  render() { 
    return (  
      <AdminLayout>
        <div className="clearfix">
          <div className="float-left">
            <h5 className="font-weight-bold text-muted">Transactions</h5>
          </div>
        </div>

        <div className="d-none d-md-block table-responsive mt-5">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Customer Email</th>
                <th scope="col">Customer Phone</th>
                <th scope="col">Amount</th>
                <th scope="col">Payment Type</th>
                <th scope="col">Status</th>
                <th scope="col">Date / Time</th>
              </tr>
            </thead>
            <tbody>
              {this.state.transaction.map((tr, key) => (
                <tr key={key}>
                  <th scope="row">{key + 1}</th>
                  <td>{tr.body.customer.name}</td>
                  <td>{tr.body.customer.email}</td>
                  <td>{tr.body.customer.phone_number}</td>
                  <td>{tr.body.charged_amount}</td>
                  <td>{tr.body.auth_model}</td>
                  <td><span class="badge badge-success">Success</span></td>
                  <td><small className="text-muted font-weight-bold">{tr.date}</small></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-block d-md-none card card-body border-0">
          <div className="card border-0">
						<div className="card-body pb-2">
							<div className="clearfix">
								<div className="float-left"><h6><small className="font-weight-bold">RWF 23,000</small></h6></div>
								<div className="float-right">
									<button className="btn" onClick={() => this.handleModal('3')}>
                    <small className="text-muted">
                    <i className="fa fa-chevron-right" />
                    </small>
                  </button>
								</div>
							</div>
              <p className="py-0 my-1"><small>Payment from</small></p>
              <p className="py-0 my-1"><small>niyitegekah2021@gmail.com</small></p>
            </div>
          </div> 

          <div className="card border-left-0 border-right-0 border-bottom-0">
						<div className="card-body pb-2">
							<div className="clearfix">
								<div className="float-left"><h6><small className="font-weight-bold">RWF 23,000</small></h6></div>
								<div className="float-right">
									<button className="btn" onClick={() => this.handleModal('2')}>
                    <small className="text-muted">
                    <i className="fa fa-chevron-right" />
                    </small>
                  </button>
								</div>
							</div>
              <p className="py-0 my-1"><small>Payment from</small></p>
              <p className="py-0 my-1"><small>niyitegekah2021@gmail.com</small></p>
            </div>
          </div> 

          <div className="card border-left-0 border-right-0 border-bottom-0">
						<div className="card-body pb-2">
							<div className="clearfix">
								<div className="float-left"><h6><small className="font-weight-bold">RWF 23,000</small></h6></div>
								<div className="float-right">
									<button className="btn" onClick={() => this.handleModal('1')}>
                    <small className="text-muted">
                    <i className="fa fa-chevron-right" />
                    </small>
                  </button>
								</div>
							</div>
              <p className="py-0 my-1"><small>Payment from</small></p>
              <p className="py-0 my-1"><small>niyitegekah2021@gmail.com</small></p>
            </div>
          </div>

          {/* <hr />
          <button className="btn btn-light mr-2"><small className="font-weight-bold"> Previous</small></button>
          <button className="btn btn-light ml-2"><small className="font-weight-bold"> Next</small></button> */}
        </div>
        
        <Modal
						show={this.state.modal}
						onHide={this.handleModal}
						size={'lg'}
						centered
					>
						<Modal.Body>
              <div className="clearfix">
                <h6 className="font-weight-bold mb-4 text-muted float-left">User Info</h6>
                <h6 className="font-weight-bold mb-4 text-muted float-right"><small className="font-weight-bold">25-01-2021</small></h6>
              </div>
              <div className="clearfix">
							  <p className="float-left"><strong>NIYITEGEKA Honore</strong></p>
							  <p className="float-right"><strong>RWF 12,000</strong></p>
              </div>
              <h6><small><strong>From: </strong>niyitegekah2021@gmail.com</small></h6>
              <h6><small><strong>Tel: </strong>+250 782 346 938</small></h6>
              <h6><small><strong>Payment Type: </strong>Mobile money</small></h6>
						</Modal.Body>
					</Modal>
      </AdminLayout>
    );
  }
}
 
export default DashboardTransactionView;