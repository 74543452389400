import React from 'react';
import Creatable from 'react-select/creatable';

import countries from './listCountries';
import { filter } from '../../../services/businessService';

import 'react-responsive-combo-box/dist/index.css';
import './index.css';

class FilterComponent extends React.Component {
	constructor(props) {
		super(props);

		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleFilterSubmit = this.handleFilterSubmit.bind(this);

		this.state = {
			filter: {
				q: [],
				w: [],
			},
			filterOptions: {
				q: null,
				w: null,
			},

			optionActive: props.optionActive,
			countryActive: props.countryActive,
		};
	}

	async componentDidMount() {
		const filterResponse = await filter();
		console.log(filterResponse);

		let countryFilter = [];
		let optionFilter = [];

		countries.countries.map(value =>
			countryFilter.push({ value: value.name, label: value.name })
		);

		filterResponse.data.data.map(value =>
			optionFilter.push({ value: value.name, label: value.name })
		);

		console.log(optionFilter);

		const filterData = {
			q: optionFilter,
			w: countryFilter,
		};

		this.setState({
			filter: filterData,
		});
	}

	handleFilterChange(e) {
		let data = null;

		if (e.name === undefined) {
			data = { ...this.state.filterOptions, q: e.value };
			this.setState({ optionActive: { value: e.value, label: e.label } });
		} else {
			data = { ...this.state.filterOptions, [e.name]: e.value };

			if (e.name === 'q') {
				this.setState({ optionActive: { value: e.value, label: e.label } });
			}
			if (e.name === 'w') {
				this.setState({ countryActive: { value: e.value, label: e.label } });
			}
		}

		this.setState({
			filterOptions: data,
		});
	}

	handleFilterSubmit() {
		let urlBuild = '/' + this.props.country + '?';

		if (this.state.filterOptions.q !== null) {
			urlBuild = urlBuild + '&q=' + this.state.filterOptions.q;
		}

		if (this.state.filterOptions.w !== null) {
			urlBuild = urlBuild + '&w=' + this.state.filterOptions.w;
		}

		window.location.href = urlBuild;
	}

	render() {
		return (
			<section className='filter-box pb-2'>
				<div className='container'>
					<form>
						<div className='row'>
							<div className='col-md-2'>
								<div className='form-group'>
									<span className='form-control'>Companies</span>
								</div>
							</div>

							<div className='col-md-4'>
								<div className='form-group'>
									<Creatable
										placeholder='Who, What ?'
										value={this.state.optionActive}
										formatCreateLabel={value => `${value}`}
										options={this.state.filter.q}
										onChange={value =>
											this.handleFilterChange({
												name: 'q',
												value: value.value,
												label: value.label,
											})
										}
									/>
								</div>
							</div>

							<div className='col-md-4'>
								<div className='form-group'>
									<Creatable
										placeholder='Where ?'
										value={this.state.countryActive}
										formatCreateLabel={value => `${value}`}
										options={this.state.filter.w}
										onChange={value =>
											this.handleFilterChange({
												name: 'w',
												value: value.value,
												label: value.label,
											})
										}
									/>
								</div>
							</div>

							<div className='col-md-2'>
								<button
									type='button'
									className='btn btn-primary btn-block'
									onClick={this.handleFilterSubmit}
								>
									<i className='fas fa-search mr-1'></i> Look for
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		);
	}
}

export default FilterComponent;
