import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import RouteConfig from './config/routes';

import SignIn from './resources/components/box/signIn';
import Home from './resources/views/company/home';
import BusinessFilterView from './resources/views/business/filter';
import BusinessSingleDetails from './resources/views/business/view';
import SearchCompanyView from './resources/views/company/search';
import ViewCompanyView from './resources/views/company/view';
import DashboardProfileView from './resources/views/dashboard/account/profile';
import SignUp from './resources/components/box/signUp';
import SignOut from './resources/components/box/signOut';
import NotFound from './resources/views/company/notfount';
import AdminLayout from './resources/layouts/AdminLayout';
import SmsMain from './resources/views/sms/SmsMain';

// import DashboardProfileView from './resources/views/dashboard/account/profile';
import DashboardTransactionView from './resources/views/dashboard/account/transaction';
import DashboardLinkView from './resources/views/dashboard/account/link';
import DashboardView from './resources/views/dashboard/account/dashboard';

import http from './services/httpService';
import ProtectedRoute from './resources/components/common/protectedRoute';

import { getBusiness } from './services/businessService';
import { getCurrentUser } from './services/authService';

export default class App extends React.Component {
	async componentDidMount() {
		// http.setAnalytic();
		// setInterval(() => http.setAnalytic(), 60000);

		const token = localStorage.getItem('token');

		if (token) {
			const url = window.location.pathname;

			const user = await getCurrentUser();

			// check if otp verified
			if (!user.data.data.is_verified) {
				if (url !== RouteConfig.signup) {
					window.location.href = RouteConfig.signup;
				}
			} else {
				try {
					await getBusiness();
				} catch (error) {
					if (url !== RouteConfig.signup) {
						window.location.href = RouteConfig.signup;
					}
				}
			}
		}
	}

	render() {
		return (
			<Router basename={process.env.REACT_APP_BASENAME}>
				<Switch>
					<Route exact path={RouteConfig.home} component={Home} />

					<Route exact path={RouteConfig.signin} component={SignIn} />
					<Route exact path={RouteConfig.signup} component={SignUp} />
					<Route exact path={RouteConfig.signout} component={SignOut} />

					<ProtectedRoute exact path='/dashboard' component={DashboardView} />

					<ProtectedRoute
						exact
						path='/dashboard/profile'
						component={DashboardProfileView}
					/>
					<ProtectedRoute
						exact
						path='/dashboard/profile/transaction'
						component={DashboardTransactionView}
					/>
					<ProtectedRoute
						exact
						path='/dashboard/profile/generate'
						component={DashboardLinkView}
					/>

					{/* <ProtectedRoute exact path='/dashboard' component={AdminLayout} /> */}

					<ProtectedRoute
						exact
						path='/dashboard/profile/sms'
						component={SmsMain}
					/>

					<Route exact path='/:country' component={SearchCompanyView} />
					<Route exact path='/:country/:slug' component={ViewCompanyView} />

					<Route path='/not-found' component={NotFound} />
				</Switch>
			</Router>
		);
	}
}
