import React from 'react';
import FilterComponent from '../filterComponent';
import Service from './service';
import { lang } from '../../../../utils/trans';

class HeroContainer extends React.Component {
  constructor(props) {
		super(props);

		this.state = {
			country: this.props.country,
			optionActive: { value: "", label: lang("Who, What ?") },
			countryActive: { value: "", label: lang("Where") }
		};
	}

  render() {
    return (
      <>
        <div className='hero__bgImage'>
          <div className='hero__content d-flex flex-column justify-content-center h-100'>
            <h2 className='font-bold text-white text-center text-title mb-1'>
            {lang('Sell Your Products & Services across Africa')}
            </h2>
  
            <p className='text-white text-center font-regular text-normal mt-3'>
              & {lang('Receive Your payments with the E-merchand Card')}
            </p>
  
            <FilterComponent country={this.state.country} optionActive={this.state.optionActive} countryActive={this.state.countryActive} />
  
            <p className='text-white text-center text-normal'>
              {lang('Just looking around')} ? {lang('Let us suggest you')} <br /> 
              {lang('Search by Name, activity Sector and Location')}
            </p>
          </div>
        </div>
  
        <Service country={this.state.country} />
      </>
    );
  }
}
 
export default HeroContainer;