import React from 'react';
import { BusinessViewHeroContainer } from '../../components/containers';
import Single from '../../components/containers/business/single';
import AppLayout from '../../layouts/AppLayout';

class Details extends React.Component {
	render() {
		return (
			<AppLayout navbarMenu={false}>
				<BusinessViewHeroContainer content={true} />
				<Single />
			</AppLayout>
		);
	}
}

export default Details;
