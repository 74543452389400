import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Rwanda from '../../../../assets/images/banner/Africa  Online Rwanda-570x228.jpg';
import Tanzania from '../../../../assets/images/banner/Africa Online Tanzania.jpg';
import Senegal from '../../../../assets/images/banner/Africa Online Senegal.jpg';
import Gabon from '../../../../assets/images/banner/Africa Online Gabon.jpg';

import { lang } from '../../../../utils/trans';

export class Cities extends React.Component {
	render() {
		return (
			<div className='cities-container py-5 mb-5 mt-5'>
				<Container>
					<Row>
						<Col lg={12}>
							<h2 className='color-black text-center text-title font-medium'>
								{lang('Happening Cities')}
							</h2>
							<p className='text-center color-grey text-normal'>
								{lang('Cities You Must Explore This Summer')}
							</p>
						</Col>
					</Row>

					<Row className='mt-5'>
						<Col lg={5} className='mb-4'>
							<Link to="/tanzania" className='city-box'>
								<Image
									src={Tanzania}
									className='img-fluid latest'
									alt='city Image'
								/>

								<div className='inner'>
									<p className='text-center color-white mb-1 text-normal'>
										Tanzania
									</p>
									<p className='text-center color-white text-small'>
										{lang('View Listings')}
									</p>
								</div>
							</Link>
						</Col>

						<Col lg={6}>
							<Row>
								<Col lg={12}>
									<Link to="/rwanda" className='city-box'>
										<Image
											src={Rwanda}
											className='img-fluid flat'
											alt='city Image'
										/>

										<div className='inner'>
											<p className='text-center color-white mb-1 text-normal'>
												Kigali City
											</p>
											<p className='text-center color-white text-small'>
											{lang('View Listings')}
											</p>
										</div>
									</Link>
								</Col>
							</Row>

							<Row className='mt-4'>
								<Col lg={6} className='mb-4'>
									<Link to="/senegal" className='city-box'>
										<Image
											src={Senegal}
											className='img-fluid small'
											alt='city Image'
										/>

										<div className='inner'>
											<p className='text-center color-white mb-1 text-normal'>
												Senegal
											</p>
											<p className='text-center color-white text-small'>
											{lang('View Listings')}
											</p>
										</div>
									</Link>
								</Col>

								<Col lg={6}>
									<Link to="/gabon" className='city-box'>
										<Image
											src={Gabon}
											className='img-fluid small'
											alt='city Image'
										/>

										<div className='inner'>
											<p className='text-center color-white mb-1 text-normal'>
												Gabon
											</p>
											<p className='text-center color-white text-small'>
											{lang('View Listings')}
											</p>
										</div>
									</Link>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
