import axios from 'axios';

axios.interceptors.response.use(null, error => {
	const expectedError =
		error.response &&
		error.response.status >= 400 &&
		error.response.status < 500;

	if (!expectedError) {
		console.log(error);
	}

	return Promise.reject(error);
});

function setJwt(jwt) {
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
}

const setAnalytic = async () => {
	const token = localStorage.getItem('analytic') ?? null;
	let data = {};

	// if not token detect user location
	if (token === null) {
		data = await axios.get('https://ipapi.co/json');
	}

	const payload = {
		token: localStorage.getItem('analytic') ?? null,
		ua: window.navigator.userAgent,
		body: data?.data,
	};

	const analytic = await axios.post(process.env.REACT_APP_ANALYTICS, payload);
	localStorage.setItem('analytic', analytic.data);
};

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
	setJwt,
	setAnalytic,
};
