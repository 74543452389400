import React from 'react';
import {
	BusinessViewHeroContainer,
	CitiesContainer,
} from '../../components/containers';
import Filters from '../../components/containers/business/filters';
import Listings from '../../components/containers/business/listings';
import AppLayout from '../../layouts/AppLayout';
import { filter, list } from '../../../services/businessService';

export default class BusinessFilterView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			country: props.match.params.country,
			filter: {
				catergories: [],
				cities: [],
			},
			filterOptions: { catergories: null, cities: null },
			list: [],
		};
	}

	async componentDidMount() {
		const filterResponse = await filter(this.state.country);
		const listResponse = await list(
			this.state.country,
			this.state.filterOptions
		);

		this.setState({
			filter: filterResponse.data.data,
			list: listResponse.data.data,
		});
	}

	selectedValueHandler = async input => {
		const listResponse = await list(this.state.country, {
			...this.state.filterOptions,
			[input.name]: input.value,
		});

		this.setState({
			list: listResponse.data.data,
		});
	};

	render() {
		return (
			<AppLayout>
				<BusinessViewHeroContainer content={false} />
				<Filters
					country={this.state.country}
					filter={this.state.filter}
					selectedValueHandler={this.selectedValueHandler}
				/>
				<Listings country={this.state.country} list={this.state.list} />
				<CitiesContainer />
			</AppLayout>
		);
	}
}
