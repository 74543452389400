import React from 'react';

export const Footer = () => {
  return (  
    <section className="footer">
      <div className="footer__bottom text-center py-4 bg-dark">
        <p className="mb-0 pb-3 text-white">Copyright © 2021 Africa XYZ. RW Tel +250 781 66</p>
        <p className="mb-0 pb-3 text-white">Give best to our clients</p>
        <div className="footer__icons">
          <i className="fab fa-instagram text-white"></i>
          <i className="fab fa-facebook text-white"></i>
          <i className="fab fa-twitter text-white"></i>
          <i className="fab fa-github text-white"></i>
        </div>
      </div>
    </section>
  );
}