import React from 'react';

export default class Filters extends React.Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			filterOptions: { catergories: null, cities: null },
		};
	}

	handleChange(e) {
		const data = {
			...this.state.filterOptions,
			[e.target.name]: e.target.value,
		};
		console.log(data);

		this.setState({
			filterOptions: {
				...this.state.filterOptions,
				[e.target.name]: e.target.value,
			},
		});
	}

	handleSubmit() {
		console.log();
		if (typeof this.props.selectedValueHandler !== 'undefined') {
			this.props.selectedValueHandler(this.state.filterOptions);
		}
	}

	render() {
		return (
			<section className='bg-white'>
				<div className='container py-3'>
					<h4 className='font-weight-bold text-muted my-5'>Find the Company</h4>

					<div className='row'>
						<div className='col-md-3'>
							<label className='text-muted font-weight-bold'>Country</label>
							<select className='custom-select' disabled={true}>
								<option>{this.props.country}</option>
							</select>
						</div>
						<div className='col-md-3'>
							<label className='text-muted font-weight-bold'>Categories</label>
							<select
								name='catergories'
								className='custom-select'
								onChange={this.handleChange}
							>
								<option value=''>Choose Category</option>

								{this.props.filter.catergories.map(data => (
									<option value={data}>{data}</option>
								))}
							</select>
						</div>
						<div className='col-md-3'>
							<label className='text-muted font-weight-bold'>City</label>
							<select
								name='cities'
								className='custom-select'
								onChange={this.handleChange}
							>
								<option value=''>Choose City</option>

								{this.props.filter.cities.map(data => (
									<option key={data} value={data}>
										{data}
									</option>
								))}
							</select>
						</div>
						<div className='col-md-3'>
							<button
								className='btn btn-primary btn-block mt-4 px-1'
								onClick={this.handleSubmit}
							>
								Filter
							</button>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
