import Frenchlang from './lang/fr.json';

export const lang = text => {
	const activeLang = localStorage.getItem('lang');

	if (activeLang === 'fr') {
		return Frenchlang[text] ?? text;
	} else {
		return text;
	}
};
