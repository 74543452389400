import React, { Component } from 'react';
import { ServiceBox } from '../box';

import './index.css';

export class HeroContainer extends Component {
	render() {
		return (
			<>
				<div className='hero__bgImage'>
					<div className='hero__content'>
						<h2 className='font-bold text-white text-center text-title mb-1'>
							Find Company
						</h2>

						<p className='text-white text-center font-regular text-normal my-4 pb-3'>
							Let's uncover the best places for companies, and what they do for all country visitors
						</p>

						<div className='container form__container mt-5'>
							<form>
								<div className='row'>
									<div className='col-md-6 pt-1'>
										<div className='input-group border-0'>
											<div className='input-group-prepend'>
												<label
													className='input-group-text border-0 bg-white'
													htmlFor='inputGroupSelect01'
												>
													What ?
												</label>
											</div>
											<select className='custom-select border-0 form-control' defaultValue="1">
												<option value='1'>Company</option>
												<option value='2'>Restaurant</option>
												<option value='3'>Beauty</option>
												<option value='1'>Agencies</option>
												<option value='2'>Shopping</option>
												<option value='3'>Hotels</option>
											</select>
										</div>
									</div>
									<div className='col-md-5 pt-1'>
										<div className='input-group border-0'>
											<div className='input-group-prepend'>
												<label
													className='input-group-text border-0 bg-white'
													htmlFor='inputGroupSelect01'
												>
													Where ?
												</label>
											</div>
											<select
												className='custom-select border-0 form-control'
												id='inputGroupSelect01'
												defaultValue="0"
											>
												<option value='0'>The City</option>
												<option value='1'>Malawi</option>
												<option value='2'>Rwanda</option>
												<option value='3'>Cameroon</option>
												<option value='2'>Ouganda</option>
												<option value='3'>Niger</option>
												<option value='2'>Nigeria</option>
												<option value='3'>South Afrique</option>
											</select>
										</div>
									</div>
									<div className='col-md-1 mt-1 text-center'>
										<button className='btn btn-primary btn-sm mt-1'>
											<i className="fa fa-search"></i>
										</button>
									</div>
								</div>
							</form>
						</div>

						<p className='text-white text-center text-normal mt-4'>
							Just looking around ? Let us suggest you <br /> something hot &
							happening!
						</p>
					</div>
				</div>

				<ServiceBox />
			</>
		);
	}
}
