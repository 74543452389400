import React from 'react';
import { Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import {
	CreateListing,
	UpdateListing,
	GetListing,
} from '../../../../services/listing';
import { GetProfile } from '../../../../services/profile';
import { getCurrentUser } from '../../../../services/authService';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getBusiness } from '../../../../services/businessService';
import countries from '../../company/listCountries';

import http from '../../../../services/httpService';

import AdminLayout from '../../../layouts/AdminLayout';

export default class DashboardProfileView extends React.Component {
	state = {
		user: {},
		business: {},

		phone: [],
		phoneLength: 1,
		phoneError: null,

		companyLoading: false,
		companySuucess: false,

		document: null,
	};

	async componentDidMount() {
		const { data } = await getCurrentUser();
		const { data: business } = await getBusiness();

		this.setState({
			user: data.data,
			business: business.data,
			phone: business.data.phone,
		});
	}

	handleBusinessInput = e => {
		this.setState({
			business: { ...this.state.business, [e.target.name]: e.target.value },
		});
	};

	handleSaveBusiness = async e => {
		e.preventDefault();

		this.setState({
			companyLoading: true,
			companySuucess: false,
		});

		await CreateListing(this.state.business, this.state.phone);

		this.setState({
			companyLoading: false,
			companySuucess: true,
		});
	};

	handlePhone = (type, key) => {
		let phoneLength = this.state.phoneLength;

		var phoneArray = Object.values(this.state.phone);

		if (type === 'add') {
			phoneLength = phoneLength + 1;
			phoneArray = [...phoneArray, '+250'];
		}

		if (type === 'remove') {
			phoneLength = phoneLength - 1;
			delete phoneArray[key];
		}

		this.setState({
			phoneLength: phoneLength,
			phone: phoneArray,
		});
	};

	handleDocument = e => {
		e.preventDefault();

		this.setState({ fileLoading: 'Uploading...' });

		if (e.target.files.length && e.target.files[0]) {
			const file = e.target.files[0];

			const url = 'https://dashboard.africa.online/api/upload';

			let formData = new FormData();
			formData.append('file', file);

			axios
				.post(url, formData)
				.then(result => {
					http.post(
						process.env.REACT_APP_AFRICA_ONLINE_API + '/business/document',
						{
							documents: [result.data],
						}
					);

					this.setState({
						fileLoading:
							'Uploaded successfully, We will review and get back to you in 3 days',
					});
				})
				.catch(err => {
					this.setState({ fileLoading: 'Upload failed! please try again' });
				});
		} else {
			this.setState({ fileLoading: null });
		}
	};

	render() {
		const { business, user } = this.state;

		return (
			<AdminLayout>
				<div className='clearfix'>
					<h2 className='text-capitalize text-title float-left'>Profile</h2>

					<button
						type='button'
						className='btn btn-primary float-right'
						data-toggle='modal'
						data-target='#document'
						disabled={business?.verified === 3 ? true : false}
					>
						<i className='fa fa-upload' /> &nbsp;{' '}
						{business?.verified === 1
							? 'Verify business'
							: business?.verified === 2
							? 'Verification Pending'
							: business?.verified === 3
							? 'Business Verified'
							: 'Verification Denied'}
					</button>
				</div>

				{this.state.companySuucess ? (
					<Alert variant='success'>Information saved successfully</Alert>
				) : null}

				<Form className='bg-white border-top border-primary mt-3 p-4'>
					<Row>
						<Col lg={6} className='mb-4'>
							<h2 className='text-capitalize text-sub-title mb-4'>Account</h2>

							<Form.Row>
								<Col>
									<Form.Group>
										<Form.Label className='color-black font-light text-normal'>
											Names
										</Form.Label>
										<Form.Control
											name='firstname'
											type='text'
											value={user.name}
											disabled
											placeholder='Eg: John'
											autoFocus={true}
										/>
									</Form.Group>
								</Col>

								<Col>
									<Form.Group>
										<Form.Label className='color-black font-light text-normal'>
											Phone Number
										</Form.Label>
										<Form.Control
											name='username'
											type='text'
											value={user.phone}
											disabled
											placeholder='Eg: Doe'
										/>
									</Form.Group>
								</Col>
							</Form.Row>

							<Form.Group>
								<Form.Label className='color-black font-light text-normal'>
									Email Address
								</Form.Label>
								<Form.Control
									name='email'
									value={user.email}
									disabled
									type='email'
									placeholder='your-email@example.com'
								/>
							</Form.Group>

							{/* <Button
								type='button'
								variant='primary'
								className='py-3 btn-block mt-5 col-sm-6'
							>
								<i className='fa fa-save mr-1'></i> Save Settings
							</Button> */}
						</Col>

						<Col lg={6}>
							<h2 className='text-capitalize text-sub-title mb-4'>Company</h2>

							<Form.Row>
								<Col>
									<Form.Group>
										<Form.Label className='color-black font-light text-normal'>
											Name
										</Form.Label>
										<Form.Control
											onChange={this.handleBusinessInput}
											name='name'
											type='text'
											required
											value={business?.name}
											placeholder='Eg: Africa XYZ'
										/>
									</Form.Group>
								</Col>

								<Col>
									<Form.Group>
										<Form.Label className='color-black font-light text-normal'>
											Sector / Activity
										</Form.Label>
										<Form.Control
											onChange={this.handleBusinessInput}
											name='sector'
											type='text'
											required
											value={business?.sector}
											placeholder='eg: Consultant'
										/>
									</Form.Group>
								</Col>
							</Form.Row>

							<Form.Row>
								<Col>
									<Form.Group>
										<Form.Label className='color-black font-light text-normal'>
											country
										</Form.Label>
										<Form.Control
											onChange={this.handleBusinessInput}
											name='country'
											as='select'
										>
											{countries.countries
												.filter(data => data.name !== 'Africa')
												.map(data => (
													<option
														value={data.name}
														selected={
															business?.country === data.name ? true : false
														}
													>
														{data.name}
													</option>
												))}
										</Form.Control>
									</Form.Group>
								</Col>

								{/* <Col>
									<Form.Group>
										<Form.Label className='color-black font-light text-normal'>
											City
										</Form.Label>
										<Form.Control
											onChange={this.CorrectCompanyState}
											name='city'
											type='text'
											required
											value={business?.city}
											placeholder='Eg: Kigali'
										/>
									</Form.Group>
								</Col> */}
							</Form.Row>
							{/* <Form.Group>
								<Form.Label className='color-black font-light text-normal'>
									Country
								</Form.Label>
								<Form.Control
									size="lg"
									as="select"
									className="mb-sm-2"
									id="inlineFormCustomSelect"
									name="country" 
									onChange={this.CorrectCompanyState}
								>
									{countries.map(country => (
										<option key={country.abbr} value={country.name} selected={business.country === country.name ? true : false}>{country.name}</option>
									))}
								</Form.Control>
							</Form.Group> */}

							<Form.Row>
								<Col>
									<Form.Group>
										<Form.Label className='color-black font-light text-normal'>
											Address
										</Form.Label>
										<Form.Control
											onChange={this.handleBusinessInput}
											name='address'
											type='text'
											required
											value={business?.address}
											placeholder='Eg: Kigali, Rwanda'
										/>
									</Form.Group>
								</Col>

								<Col>
									<Form.Group>
										<Form.Label className='color-black font-light text-normal'>
											Website
										</Form.Label>
										<Form.Control
											onChange={this.handleBusinessInput}
											name='website'
											type='text'
											required
											value={business?.website}
											placeholder='https://www.website.domain'
										/>
									</Form.Group>
								</Col>
							</Form.Row>

							<Form.Row>
								{/* {
									!this.state.loading ? 
									<Col>
										<Form.Group>
											<Form.Label className='color-black font-light text-normal'>
												Phone
											</Form.Label>
											<PhoneInput
												regions={'africa'}
												value={business?.phone[0].toString() ?? ''}
												onChange={phone => this.setState({
													business: {...business, 'phone': '+' + phone}
												})}
												countryCodeEditable={false}
											/>
										</Form.Group>
									</Col> : null
								} */}

								{/* <Col>
									<Form.Group>
										<Form.Label className='color-black font-light text-normal'>
											Email
										</Form.Label>
										<Form.Control
											onChange={this.CorrectCompanyState}
											name='email'
											type='email'
											required
											value={business?.email}
											placeholder='eg: '
										/>
									</Form.Group>
								</Col> */}
							</Form.Row>

							<Form.Group>
								{Object.keys(this.state.phone).map(([key, value]) => (
									<div className='mb-3'>
										<Form.Label className='color-black font-light text-normal'>
											Phone
										</Form.Label>
										<div className='d-flex'>
											<PhoneInput
												regions={'africa'}
												inputClass='w-100'
												value={this.state.phone[key]}
												onChange={input =>
													this.setState({
														phone: { ...this.state.phone, [key]: input },
													})
												}
												countryCodeEditable={false}
											/>
											{this.state.phoneLength > 0 && (
												<Button
													className='flex-shrink-1 ml-1'
													variant='danger'
													onClick={() => this.handlePhone('remove', key)}
												>
													<i
														className='fas fa-trash'
														style={{ fontSize: '12px' }}
													></i>
												</Button>
											)}
										</div>
									</div>
								))}

								{this.state.phoneError && (
									<h6 className='text-danger mb-3'>
										<small>{this.state.phoneError}</small>
									</h6>
								)}

								<button
									type='button'
									className='btn btn-sm btn-danger borders mb-4 pull-right'
									disabled={this.state.phoneLength >= 4 ? true : false}
									onClick={() => this.handlePhone('add', null)}
								>
									Add New Phone
								</button>
							</Form.Group>

							<Form.Group>
								<Form.Label className='color-black font-light text-normal'>
									Description
								</Form.Label>
								<Form.Control
									onChange={this.handleBusinessInput}
									as='textarea'
									value={business?.description}
									name='description'
									rows={5}
									placeholder='Your description here...'
									required
								/>
							</Form.Group>

							<Button
								type='button'
								variant='primary'
								className='py-3 btn-block mt-4 col-sm-6'
								onClick={this.handleSaveBusiness}
							>
								{this.state.companyLoading ? (
									<Spinner size='sm' animation='border' role='status'>
										<span className='sr-only'>Loading...</span>
									</Spinner>
								) : (
									<>
										{' '}
										<i className='fa fa-save mr-1'></i> Save information{' '}
									</>
								)}
							</Button>
						</Col>
					</Row>
				</Form>
				<div class='modal fade' id='document'>
					<div class='modal-dialog'>
						<div class='modal-content'>
							<div class='modal-body'>
								<h5 className='font-weight-bold text-muted'>
									Upload business document
								</h5>
								<hr />

								<div className='my-3'>
									{this.state.fileLoading !== null ? (
										<span className='text-success font-weight-bold'>
											<i className='fa fa-check-circle' /> &nbsp;{' '}
											{this.state.fileLoading}
										</span>
									) : null}
								</div>

								<Form.Group>
									<Form.Label className='color-black font-light text-normal mb-3'>
										<strong className='text-muted'>Legal Document</strong>
									</Form.Label>
									<Form.File name='file' onChange={this.handleDocument} />
								</Form.Group>

								<div>
									{business.verified === 2 && (
										<>
											{business.documents?.map(b => (
												<a
													className='font-weight-bold cursor'
													href={b}
													target='_blank'
												>
													<i className='fas fa-arrow-right' />
													&nbsp; Review your Document
												</a>
											))}
										</>
									)}
								</div>
								{business.documents?.length !== 0 ? (
									<Button
										type='button'
										variant='primary'
										className='py-1 mt-3'
										onClick={this.handleSaveBusiness}
									>
										{this.state.companyLoading ? (
											<Spinner size='sm' animation='border' role='status'>
												<span className='sr-only'>Loading...</span>
											</Spinner>
										) : (
											<>
												{' '}
												<i className='fa fa-save mr-1'></i> Update Document
											</>
										)}
									</Button>
								) : (
									<Button
										type='button'
										variant='primary'
										className='py-1 mt-3'
										onClick={this.handleSaveBusiness}
									>
										{this.state.companyLoading ? (
											<Spinner size='sm' animation='border' role='status'>
												<span className='sr-only'>Loading...</span>
											</Spinner>
										) : (
											<>
												{' '}
												<i className='fa fa-save mr-1'></i> Save Document
											</>
										)}
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			</AdminLayout>
		);
	}
}
