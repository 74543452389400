import React from 'react';
import { Navbar, Footer } from '../components/common';

const AppLayout = props => {
	return (
		<>
			<Navbar menu={props.navbarMenu ?? true} />
			{props.children}
		<Footer />
		</>
	);
};

export default AppLayout;
