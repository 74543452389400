import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import '../index.css';

export class BusinessViewHeroContainer extends Component {
	render() {
		return (
			<div
				className={
					this.props.content
						? 'business__view__hero__bgImage'
						: 'business__view__hero__bgImage-small'
				}
			>
				{this.props.content ? (
					<div className='hero__content ml-md-5'>
						<h4 className='font-bold text-white text-left text-title mb-2'>
							<i className='fa fa-business-time hero__business__icon'></i> Liman
							Restaurant{' '}
							<Image
								src='https://ml0xnijd7w94.i.optimole.com/efXRFw-tBG5c2HL/w:auto/h:auto/q:55/https://main.mylistingtheme.com/wp-content/themes/my-listing/assets/images/tick.svg'
								className='img-fluid'
								width='20px'
								alt='Verified'
							/>
						</h4>

						<p className='text-white text-left text-small'>
							Innovative cooking, paired with fine wines
						</p>
					</div>
				) : null}
			</div>
		);
	}
}
