import React from 'react';
import footerCountries from './listCountries';
import './css/footer.css';

import { lang } from '../../../utils/trans';

const Footer = () => {
	const handleChangeLang = () => {
		const lang = localStorage.getItem('lang');

		if(lang) {
			if(lang === 'fr') {
				localStorage.setItem('lang', 'en');	
			} else {
				localStorage.setItem('lang', 'fr');
			}
		} else {
			localStorage.setItem('lang', 'fr');
		}

		window.location.reload();
	}

  return (  
		<>
    <footer className="bg-danger">
      <footer className='pt-5 pb-1'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-3 mb-4'>
								<h4 className='font-bold color-white'>AFRICA XYZ</h4>
								<p className='text-left text-justify color-white mt-4'>
									<p style={{ lineHeight: '2em',}}>
										{lang('We are a Pan-African multinational with headquaters in Rwanda and our mission is to empower Africa through E-commerce and Payments.')}
									</p>

									<div className="mt-4">
										<a href="https://www.facebook.com/africaxyz" target="_blank" className="mx-3 text-white">
											<i className="fab fa-facebook fa-2x" />
										</a>
										<a href="https://www.instagram.com/afrikaxyz" target="_blank" className="mx-3 text-white">
											<i className="fab fa-instagram fa-2x" />
										</a>
										<a href="#" target="_blank" className="mx-3 text-white">
											<i className="fab fa-linkedin fa-2x" />
											</a>
									</div>

									<div className="mt-5">
									<div className="dropdown w-100">
										<button type="button" className="btn btn-defalt bg-white dropdown-toggle btn-block" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										{
												localStorage.getItem('lang') === 'fr' ? 'French' : 'English'
											}
										</button>
										<div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
											<a className="dropdown-item" href="#" onClick={handleChangeLang}	>
											{
												localStorage.getItem('lang') === 'fr' ? 'English' : 'French'
											}
											</a>
										</div>
									</div>
									</div>
								</p>
							</div>

							<div className='col-md-6 mb-4'>
								<div className='row'>
									<div className="col">
										<h5 className='font-bold color-white'>{lang('AFRICA DIRECTORIES')}</h5>
									</div>
								</div>

								<div className='row d-none d-md-block'>
									{footerCountries.footerCountries.map(data => (
										<a key={data.abbr} href={'/' + data.name.toLowerCase()} className="col">
											<img
												src={data.image}
												className='img-fluid my-2'
												alt={lang(data.name)}
											/>
										</a>
									))}
								</div>

								<div className='row d-block d-md-none text-center'>
									{footerCountries.footerCountries.slice(0, 18).map(data => (
										<a key={data.abbr} href={'/company/search/' + data.name} className="col">
											<img
												src={data.image}
												className='img-fluid my-3'
												alt={lang(data.name)}
											/>
										</a>
									))}
									<h6 className="pt-4"><a className="btn text-white" href='#countriesMore' data-toggle='modal'>{lang('See more')}</a></h6>
								</div>

							</div>

							<div className='col-md-3'>
								<div className="card my-3" style={{ backgroundColor: "#003F5B"}}>
									<div className="card-body">
										<h5 className="font-weight-bold text-white mb-4">{lang('Our Headquaters')}</h5>
										<div className="media my-2 text-white">
											{lang('Address')}:
											<div className="media-body pl-2">
												<small>Alliance Tower, Kigali - Rwanda</small> 
											</div>
										</div>
										<div className="media my-2 text-white">
											{lang('Email')}:
											<div className="media-body pl-2">
												<small>365@africa.xyz</small>
											</div>
										</div>
										<div className="media my-2 text-white">
											{lang('Phone')}:
											<div className="media-body pl-2">
												<small>+250 733 662 704</small>
											</div>
										</div>
										<div className="media my-2 text-white">
											{lang('Opening hours')}:
											<div className="media-body pl-2">
												<small>8am-5pm Mon - Fri</small> 
											</div>
										</div>
									</div>
								</div>

								<div className="card my-3 border-0" style={{backgroundColor: 'transparent'}}>
									<div className="card-body">
										<h5 className="font-weight-bold text-white mb-4">{lang('INFO & LINKS')}</h5>
										<p><a className="text-white" href="#">{lang('Our locations')}</a></p>
										<p>
											<a className="text-white" href="#">
												{lang('Work at AFRICA XYZ')}
											</a>
										</p>
										<p>
											<a className="text-white" href="#">
												{lang('Corporate Informations')}
											</a></p>
										<p><a className="text-white" href="#">{lang('Questions ?')} &nbsp;&nbsp; {lang('Contact Us')}</a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<p className="text-center text-white pt-4">Copyright &copy; 2021 Allrights reserved by Africa XYZ.</p>
				</footer>
    </footer>

		<div className='modal fade bd-example-modal-lg' id='countriesMore'>
					<div className='modal-dialog modal-lg'>
						<div className='modal-content'>
							<div className="modal-header">
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<div className='row text-center'>
									{footerCountries.footerCountries.map(data => (
										<div key={data.abbr} className='col-md-2 col-4 mb-3'>
											<a href={'/c/' + data.name}>
												<img
													src={data.image}
													className='img-fluid'
													width='40px'
													height='40px'
													alt={lang(data.name)}
												/>
												<p className='text-muted mt-2'>{lang(data.name)}</p>
											</a>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				</>
  );
}
 
export default Footer;