import React from 'react';
import {Cities} from './container/cities';
import Claim from './container/claim';
import HeroContainer from './container/heroContainer';
import Testimonial from './container/testimonial';
import Layout from './layout';

export default class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			country: localStorage.getItem('country') ?? 'africa'
		}
	}
	
	render() {
		return (
			<Layout country={this.state.country}>
				<HeroContainer country={this.state.country} />
				<Cities />
				<Claim />
				<Testimonial />
			</Layout>
		);
	}
}