import http from './httpService';
import { newApi } from '../config/apiUrl';

export function businessData() {
  return http.get(newApi + '/user/test');
}

export function generateLink(link) {
  return http.post(newApi + '/link', link)
}

export function getGeneratedLink() {
  return http.get(newApi + '/link');
}

export function deleteLink(id) {
  return http.delete(newApi + '/link/' + id);
}

export function getTransaction() {
  return http.get(newApi + '/transaction');
}