import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentUser } from '../../../services/authService';
import './css/navbar.css';
import countries from './listCountries';
import Logo from '../../../assets/images/banner/africa.xyz Logo.png'

import { lang } from '../../../utils/trans';

const Navbar = ({country, theme}) => {
	const [navbar, setNavbar] = useState(theme ? theme : false);
	const [user, setUser] = useState('');
	
	const getUser = async () => {
		const { data } = await getCurrentUser();
		setUser(data.data);
	};

	useEffect(() => {
		getUser();
	}, []);

	const changeBackground = () => {
		if (window.scrollY >= 50) return setNavbar(true);

		return setNavbar(theme ? theme : false);
	};

	window.addEventListener('scroll', changeBackground);

	const handleRedirect = () => {
		window.location.href='/';
	}

	const handleChangeLang = () => {
		const lang = localStorage.getItem('lang');

		if(lang) {
			if(lang === 'fr') {
				localStorage.setItem('lang', 'en');	
			} else {
				localStorage.setItem('lang', 'fr');
			}
		} else {
			localStorage.setItem('lang', 'fr');
		}

		window.location.reload();
	}

  return (  
    <>
      <header>
					<nav className={navbar
						? 'navbar navbar-expand-lg navbar-light fixed-top active shadow'
						: 'navbar navbar-expand-lg fixed-top navbar-light'}>
						<div className='container'>
							<img src={Logo} className='navbar-brand img-fluid w-50' onClick={handleRedirect} />
							<button
								className='navbar-toggler'
								type='button'
								data-toggle='collapse'
								data-target='#navbarSupportedContent'
								aria-controls='navbarSupportedContent'
								aria-expanded='false'
								aria-label='Toggle navigation'
							>
								<i
									className={
										navbar ? 'fa fa-bars text-black-50' : 'fa fa-bars text-white'
									}
								></i>
							</button>

							<div className='collapse navbar-collapse' id='navbarSupportedContent'>
								<ul className='navbar-nav ml-auto'>
									<li className='nav-item mx-2 my-lg-4'>
										{countries.countries
											.filter(c => c.name.toLowerCase() === country.toLowerCase())
											.map(data => (
												<a key={data.abbr} className="nav-link" href='#countries' data-toggle='modal'>
													<img src={data.image} width="20" height="20" alt="" className="mr-1"/>
													<span>{data.name === lang('Africa') ? lang('Countries') : data.name} <i className="fa fa-chevron-down"></i></span>
												</a>
											))
                    }
									</li>
									{!user && 
										<>
											<li className='nav-item mx-2 my-lg-4'>
												<Link to="/signIn" className={navbar ? 'nav-link' : 'nav-link'}>
													<i className='fas fa-user'></i> &nbsp; {lang('Sign In')}
												</Link>
											</li>
											<li className='nav-item mx-2 my-lg-4 text-center mt-4'>
												<Link to="/signUp"  className={navbar ? 'nav-link border rounded' : 'nav-link border rounded'}>
													<i className="fa fa-plus"></i> &nbsp; {lang('Add Business')}
												</Link>
											</li>
										</>
									}
									{user && <li className='nav-item mx-2 my-lg-4'>
										<Link to="/dashboard" className={navbar ? 'nav-link' : 'nav-link'}>
											<i className='fas fa-user'></i> &nbsp; {user.name}
										</Link>
									</li>}

									{/* <li className="nav-item mx-2 my-lg-4 dropdown">
										<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" ariaExpanded="false">
											{
												localStorage.getItem('lang') === 'fr' ? 'French' : 'English'
											}
										</a>
										<div className="dropdown-menu" aria-labelledby="navbarDropdown">
											<a className="dropdown-item" onClick={handleChangeLang}>
											{
												localStorage.getItem('lang') === 'fr' ? 'English' : 'French'
											}
											</a>
										</div>
									</li> */}
								</ul>
							</div>
						</div>
					</nav>
				</header>

        <div className='modal fade bd-example-modal-lg' id='countries'>
					<div className='modal-dialog modal-lg'>
						<div className='modal-content'>
							<div className="modal-header">
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<div className='row text-center'>
									{countries.countries.map(data => (
										<div key={data.abbr} className='col-md-2 col-4 mb-3'>
											<a href={'/' + data.name.toLowerCase()}>
												<img
													src={data.image}
													className='img-fluid'
													width='40px'
													height='40px'
													alt={data.name}
												/>
												<p className='text-muted mt-2'>{lang(data.name)}</p>
											</a>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
    </>
  );
}
 
export default Navbar;
