import React from 'react';

const Single = () => {
  return (  
    <section className="my-4">
      <div className="container">
        <h6 className="text-muted"><small>Agences Immobilieres</small> </h6>
        <h4 className="text-primary font-weight-bold">RESIDENTIAL PEOPLE</h4>
        <small className="text-muted pb-4">Kigali City</small>
        <hr/>
        <div className="row">
          <div className="col-md-6">
            <div className="card shadow border-0 my-2">
              <div className="card-body">
                <div className="media">
                  <i className="fa fa-bars mt-1"></i>
                  <div className="media-body pl-2">
                    <p className="font-weight-bold mb-3">Description</p>
                  </div>
                </div>
                <p className="text-muted">
                  The Liman Restaurant means port in the Turkish language, 
                  however the restaurant opens its doors to all aspects of the Mediterranean kitchen. 
                  The kitchen will be mostly focused on Mediterranean food; 
                  the owners of the restaurant are young professional chefs who can bring new, exciting tastes to Angel, 
                  Islington which will show through in the quality of food they prepare.
                </p>
              </div>
            </div>
            <div className="card shadow border-0 my-3">
              <div className="card-body">
                <div className="media">
                  <i className="fa fa-tasks text-muted mt-1"></i>
                  <div className="media-body pl-2">
                    <p className="font-weight-bold text-muted">Follow Us</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <a href="#" className="text-muted"><small><i className="fab fa-facebook fa-2x my-2"></i></small></a>
                  </div>
                  <div className="col-md-4">
                    <a href="#" className="text-muted"><small><i className="fab fa-instagram fa-2x my-2"></i></small></a>
                  </div>
                  <div className="col-md-4">
                    <a href="#" className="text-muted"><small><i className="fab fa-twitter fa-2x my-2"></i></small></a>
                  </div>
                  <div className="col-md-4">
                    <a href="#" className="text-muted"><small><i className="fab fa-linkedin fa-2x my-2"></i></small></a>
                  </div>
                  <div className="col-md-4">
                    <a href="#" className="text-muted"><small><i className="fab fa-github fa-2x my-2"></i></small></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card shadow border-0">
              <div className="card-body pb-1">
                <div className="media">
                  <i className="fa fa-map text-muted mt-1"></i>
                  <div className="media-body pl-2">
                    <p className="font-weight-bold text-muted">Region</p>
                  </div>
                </div>
                <p className="text-muted mt-1">
                  <i className="fa fa-map-pin"></i> &nbsp;
                  London
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card shadow border-0 my-2">
              <div className="card-body pb-1">
                <div className="clearfix text-muted">
                  <div className="float-left">
                    <div className="media">
                      <i className="fa fa-clock text-primary mt-1"></i>
                      <div className="media-body pl-2">
                        <p className="font-weight-bold">Open</p>
                      </div>
                    </div>
                  </div>
                  <div className="float-right">
                    <p>Open 24/7</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card shadow border-0 my-3">
              <div className="card-body pb-1">
                <div className="clearfix text-muted">
                  <div className="float-left">
                    <div className="media">
                      <i className="fa fa-tasks text-primary mt-1"></i>
                      <div className="media-body pl-2">
                        <p className="font-weight-bold">Category</p>
                      </div>
                    </div>
                  </div>
                  <div className="float-right">
                    <p>Agences Immobilieres</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card shadow border-0 my-3">
              <div className="card-body">
                <div className="media text-muted">
                  <i className="fa fa-envelope text-primary mt-1"></i>
                  <div className="media-body pl-2">
                    <p className="font-weight-bold mb-4">Contact Bussiness</p>
                    <form>
                      <input type="text" className="form-control my-3" placeholder="Names" />
                      <input type="email" className="form-control my-3" placeholder="Email" />
                      <input type="number" className="form-control my-3" placeholder="Phone" />
                      <textarea name="" rows="6" placeholder="Message" className="form-control"></textarea>
                      <button className="btn btn-primary btn-sm btn-block mt-4">CONTACT</button>
                    </form>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
 
export default Single;