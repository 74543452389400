import { reverse } from 'named-urls';
import React from 'react';
import { Link } from 'react-router-dom';
import RouteConfig from '../../../../config/routes';

const data = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const Listings = props => {
	return (
		<section className='bg-white py-5'>
			<div className='container'>
				<h6 className='font-weight-bold text-muted mb-4'>
					The available companies are: {props.list.length}
				</h6>
				<div className='row'>
					{props.list.map(data => (
						<div className='col-lg-3 col-md-4 col-sm-6 my-2'>
							<Link
								to={reverse(RouteConfig.country.view, {
									country: props.country,
									slug: data._id,
								})}
							>
								<div className='card shadow border-0'>
									<div className='card-body'>
										<h6 className='font-weight-bold'>{data.name}</h6>
										<Link
											to={reverse(RouteConfig.country.view, {
												country: props.country,
												slug: data._id,
											})}
										>
											<div className='media text-muted'>
												<small>
													<i className='fa fa-globe mt-1'></i>
												</small>
												<div className='media-body pl-1'>
													<small className='text-muted'>
														{data.website.length > 35
															? data.website.substring(0, 35) + ' ...'
															: data.website.substring(0, 35)}
													</small>
												</div>
											</div>
										</Link>
										<p className='text-muted pt-3'>
											<small>{data.description}</small>
										</p>
										<div className='clearfix'>
											<div className='float-left'>
												<div className='media text-muted'>
													<small>
														<i className='fa fa-map-marker mt-1'></i>
													</small>
													<div className='media-body pl-1'>
														<small className='text-muted'>{data.address}</small>
													</div>
												</div>
											</div>
											<div className='float-right'>
												<div className='media text-muted'>
													<small>
														<i className='fa fa-phone'></i>
													</small>
													<div className='media-body pl-1'>
														<small>{data.phone}</small>
													</div>
												</div>
											</div>
										</div>
										<small className='float-right mt-4'>
											<strong>More details</strong>
										</small>
									</div>
								</div>
							</Link>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Listings;
