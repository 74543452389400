import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tour from '../../../assets/images/icon/tour.svg';
import { register } from '../../../services/authService';
import Logo from '../../../assets/images/banner/africa.xyz Logo.png';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { OutlinedInput } from '@material-ui/core';
import countries from '../../views/company/listCountries';
import axios from 'axios';
import { getCurrentUser } from '../../../services/authService';
import http from '../../../services/httpService';
import { Modal, Button, Form } from 'react-bootstrap';

import uploadDocument from './uploadDocument';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOTP = this.handleOTP.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);

    this.state = {
      redirect: new URLSearchParams(this.props.location.search).get("redirect"),
      page: new URLSearchParams(this.props.location.search).get("page"),
      data: {
        name: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
      },
      company: {
        companyName: '',
        sector: '',
        country: '',
        website: '',
        address: '',
        description: ''
      },
      verification: {
        phone: '',
        country: ''
      },
      verify: false,
      buttonDisabled: false,
      isPasswordShown: false,
      step: 1,
      error: '',
      loading: false,
      success: false,
      otp: '',
      otpInput: '',
      otpStatus: true,
      phoneValid: true,




      verifyUserOtpModal: false,
      verifyOtpInput: '',
      verifyOtpError: null,
    }
  }

  handleRedirect = () => {
		window.location.href='/';
	}

  async componentDidMount() {
    const token = localStorage.getItem('token');

    if (token) {
      const user = await axios.get(process.env.REACT_APP_AFRICA_ONLINE_API + '/auth/user');

      if (!user.data.data.is_verified) {
        this.setState({ verifyUserOtpModal: true, step: 1 });
      } else {
        if (this.state.step === 1) {
          this.setState({verifyUserOtpModal: false, buttonDisabled: false, step: 2});
        }
      }
    }
  }

  handlechange(e) {
    this.disabledButton()
    if(this.state.step === 1) return this.setState({
      data: {...this.state.data, [e.target.name]: e.target.value}
    });

    return this.setState({
      company: {...this.state.company, [e.target.name]: e.target.value}
    })
  }

  handleClickShowPassword = () => {
    const {isPasswordShown} = this.state;
    this.setState({isPasswordShown: !isPasswordShown});
  };

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({error: ''});

    if(this.state.data.username.length > 10 && this.state.data.username.length < 15) {
      try {
        const response = await register(this.state.data);
        // this.setState({
        //   loading: false,
        //   success: response.data.status,
        //   otp: response.data.OTP
        // });
        window.location = '/signIn';
      } catch (ex) {
        console.log(ex.response)
        if(ex.response && ex.response.status === 400) {
          this.setState({error: ex.response.data.data.message});
        }
      }
    } else {
      this.setState({ phoneValid: false });
    }
  }

  async handleOTP(e) {
    e.preventDefault();

    this.setState({
      loading: true
    });

    if(this.state.otpInput == this.state.otp) {
      setTimeout(function() {
        if(this.state?.redirect) {
          window.location = '/' + localStorage.getItem('country') + '/' + this.state.page;
        } else {
          window.location = '/signIn';
        }
      }, 1000);
    } else {
      alert('OTP Validation Failed');

      this.setState({
        loading: false
      });
    }
  }

  verify = () => {
    const {verify} = this.state;
    if(!verify) return this.setState({verify: true});
  }

  disabledButton = () => {
    const {data, company} = this.state;

    if(this.state.step === 1) {
      if (data.name && data.password && data.email && data.confirm_password ) return this.setState({buttonDisabled: true});
      return this.setState({buttonDisabled: true});
    } else if(this.state.step === 2) {
      if (company.companyName && company.address && company.sector && company.website ) return this.setState({buttonDisabled: true});
      return this.setState({buttonDisabled: false});
    }
    
  }

  handlePhoneChange(phone, country) {
    const inputPhone = '+' + phone;

    if(this.state.step === 1) {
      this.setState({
        phoneValid: false,
        data: {...this.state.data, username: inputPhone, country: country.name}
      });
    } else if(this.state.step === 3){
      this.setState({
        phoneValid: false,
        data: {...this.state.verification, phone: inputPhone, country: country.name}
      });
    }
    

    if(inputPhone.length > 10 && inputPhone.length < 15) {
      this.setState({ phoneValid: true });
    }
  }

  handleRegister = async e => {
    e.preventDefault();

    try {
      const response = await axios.post(process.env.REACT_APP_AFRICA_ONLINE_API + '/auth/user', {
        name: this.state.data.name,
        email: this.state.data.email,
        phone: this.state.data.phone,
        account: 'AFRICA',
        password: this.state.data.password,
        confirm_password: this.state.data.password
      });

      localStorage.setItem('token', response.data.data);

      window.location.reload();

      this.setState({ verifyUserOtpModal: true });
    } catch(error) {
      console.log(error);
    }
  }

  handleCompany = async e => {
    e.preventDefault();

    const dataJSON = {
      name: this.state.company.companyName,
      sector: this.state.company.sector,
      country: this.state.company.country,
      address: this.state.company.address,
      website: this.state.company.website,
      phone: [],
      description: this.state.company.description,
    };

    try {
      await http.post(process.env.REACT_APP_AFRICA_ONLINE_API + '/business', dataJSON);
      this.setState({buttonDisabled: false, step: 3});
    } catch (error) {
      console.log(error);
    }
  }

  handleVerification = e => {
    e.preventDefault();
    console.log(this.state.verification);
    window.location.href="/dashboard";
  }


  handleVerifyOTP = async () => {
    try {
      await http.post(process.env.REACT_APP_AFRICA_ONLINE_API + '/auth/user/verify', {
        otp: Number(this.state.verifyOtpInput)
      });

      this.setState({ buttonDisabled: false, step: 2, verifyUserOtpModal: false });
    } catch (ex) {
      this.setState({
        verifyOtpError: ex.response.data.detail,
      });
    }
  }

  handleChangeOTPInput = e => {
    if (e.target.value.length === 6) {
      this.setState({
        verifyOtpInput: e.target.value,
        verifyOtpError: null,
      });
    } else {
      this.setState({
        verifyOtpError: 'OTP must be 6 character',
      });
    }
  }

  render() {
    const {isPasswordShown, data, company, step, verification} = this.state;

    return (
      <section className="bg-image px-xl-5">
        <Modal show={this.state.verifyUserOtpModal} centered backdrop="static">
        <Modal.Body>
          <h5>Verify OTP</h5>

          {
            this.state.verifyOtpError !== null ? 
            <div class="alert alert-danger" role="alert">
              {this.state.verifyOtpError}
            </div> : null
          }

          <Form.Group>
            <Form.Control type="number" placeholder="123456" onChange={this.handleChangeOTPInput} />
          </Form.Group>
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleVerifyOTP}>
            Verify
          </Button>
        </Modal.Footer>
      </Modal>

        <div className="d-flex flex-column justify-content-center align-items-center h-100 mx-xl-5 px-xl-5">
          <div className="container">
            <div className="card mx-xl-5">
              <div className="card-body">
                <div className="clearfix">
                  <div className="float-left">
                    <h6 className="font-weight-bold">AFRICA <strong className="text-danger">|</strong><span className="text-muted"> XYZ</span> </h6>
                  </div>
                  <div className="float-right">
                    <h5 className="bg-special d-none d-md-block">New user registration</h5>
                    <h6 className="bg-special d-block d-md-none">New user registration</h6>
                  </div>
                </div>
                
              </div>
              <div className="card-header">
                <ul class="steps my-4 d-none d-md-flex">
                  <li class='step step-active'>
                    <div class="step-content">
                      <span class="step-circle">{step === 1 ? "1" : <i className="fa fa-check" />}</span>
                      <span class="step-text text-center">Account</span>
                    </div>
                  </li>
                  <li class={`step ${step === 1 ? 'step-muted' : 'step-active'}`}>
                    <div class="step-content">
                      <span class="step-circle">{step !== 3 ? "2" : <i className="fa fa-check" />}</span>
                      <span class="step-text text-center">Add Business</span>
                    </div>
                  </li>
                  <li class={`step ${step === 3 ? 'step-active' : 'step-muted'}`}>
                    <div class="step-content">
                      <span class="step-circle">3</span>
                      <span class="step-text text-center">Verify Business</span>
                    </div>
                  </li>
                </ul>
                <ul class="steps my-4 d-flex d-md-none">
                  <li class='step step-active'>
                    <div class="step-content">
                      <span class="step-circle">{step === 1 ? "1" : <i className="fa fa-check" />}</span>
                      <span class="step-text text-center">Account</span>
                    </div>
                  </li>
                  <li class={`step ${step === 1 ? 'step-muted' : 'step-active'}`}>
                    <div class="step-content">
                      <span class="step-circle">{step !== 3 ? "2" : <i className="fa fa-check" />}</span>
                      <span class="step-text text-center">Add Business</span>
                    </div>
                  </li>
                  <li class={`step ${step === 3 ? 'step-active' : 'step-muted'}`}>
                    <div class="step-content">
                      <span class="step-circle">3</span>
                      <span class="step-text text-center">Verify Business</span>
                    </div>
                  </li>
                </ul>
              </div>
              <form onSubmit={step === 1 ? this.handleRegister : this.handleCompany}>
                <div className="card-body">
                  <div className="row justify-content-center">
                    {step === 1 ?
                      <>
                        <div className="col-lg-5">
                          <div className="row my-4">
                            <div className="col-md-6 pl-0">
                              <TextField size="small" value={data.name} name="name" label="Names" onChange={this.handlechange} fullWidth variant="outlined" />
                            </div>
                            <div className="col-md-6 mx-0 pr-0">
                              <TextField size="small" value={data.email} name="email" label="Email" onChange={this.handlechange} fullWidth variant="outlined" />
                            </div>
                            {/* <PhoneInput
                              country={'rw'}
                              regions={'africa'}
                              inputClass="form-control w-100"
                              buttonClass=""
                              containerClass="p-0 my-3"
                              placeholder="(999) 999 999 999"
                              value={data.username}
                              onChange={(phone, country) => this.handlePhoneChange(phone, country)}
                              countryCodeEditable={false}
                            />

                            {
                              this.state.phoneValid ? '' : <p className="text-danger text-left">Invalid phone</p>
                            } */}
                            <FormControl fullWidth size="small" className="mt-3" variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-amount">Password</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                type={this.state.isPasswordShown ? 'text' : 'password'}
                                name="password"
                                value={data.password}
                                onChange={this.handlechange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      // onMouseDown={handleMouseDownPassword}
                                    >
                                      {this.state.isPasswordShown ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                labelWidth={70}
                              />
                            </FormControl>
                            <FormControl fullWidth size="small" className="mt-3" variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-amount">Password again</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                type={this.state.isPasswordShown ? 'text' : 'password'}
                                name="confirm_password"
                                value={data.confirm_password}
                                onChange={this.handlechange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      // onMouseDown={handleMouseDownPassword}
                                    >
                                      {this.state.isPasswordShown ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                labelWidth={120}
                              />
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-lg-5 offset-md-1">
                          <>
                            <h5 className="text-center">Phone Number Verification</h5>
                            <div className="alert alert-primary">
                              <div className="media">
                                <i className="fa fa-info-circle fa-2x" />
                                <div className="media-body ml-2">
                                  <p style={{fontSize: 11}}> To help protect your account, we ask you to verify your
                                  phone number via SMS. You'll receive a 6-digit code to
                                  enter and verify your account. </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="font-weight-bold mb-1">1. Enter phone number</h6>
                            <PhoneInput
                              country={'rw'}
                              regions={'africa'}
                              inputClass="form-control w-100"
                              buttonClass="border-right-0 bg-white"
                              containerClass="p-0 mb-3"
                              placeholder="(999) 999 999 999"
                              value={data.phone}
                              onChange={
                                phone => this.setState({ data: {...data, phone: phone} })
                              }
                              countryCodeEditable={false}
                            />
                            <button className="btn btn-primary btn-sm py-2 btn-block">SEND CODE</button>
                          </>
                        </div>
                      </> :  
                      step === 2 ? <>
                      <div className="col-lg-5">
                        <>
                          <TextField size="small" value={company.companyName} name="companyName" label="Company name" onChange={this.handlechange} className="my-3" fullWidth variant="outlined" />
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Country"
                            size="small"
                            fullWidth
                            name="country"
                            onChange={e => this.setState({ company: {...this.state.company, country: e.target.value} })}
                            helperText="Please select your country"
                            variant="outlined"
                          >
                            {countries.countries.map((option) => (
                              <MenuItem key={option.abbr} value={option.name} selected={
                              company?.country === option.name ? true : false
                            }>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField size="small" value={company.address} name="address" label="Address" onChange={this.handlechange} className="my-3" fullWidth variant="outlined" />
                        </>
                      </div>
                      <div className="col-lg-5 offset-lg-1">
                        <>
                          <TextField size="small" value={company.sector} name="sector" label="Sector / Activity" onChange={this.handlechange} className="my-3" fullWidth variant="outlined" />
                          <TextField size="small" value={company.website} name="website" label="Website" onChange={this.handlechange} className="my-3" fullWidth variant="outlined" />
                          <TextField size="small" value={company.description} name="description" label="Description" onChange={this.handlechange} className="my-3" fullWidth multiline variant="outlined" />
                        </>
                      </div>
                    </> : 
                    <>
                    <Form>
                      <Form.Group className="mb-4">
                        <Form.File label="Legal Document" custom required />
                      </Form.Group>

                      <button type="submit" className="btn btn-info btn-sm px-5 mr-2">Verify</button>
                      <button className="btn btn-danger btn-sm px-5" onClick={() => window.location.href="/dashboard"}>Skip</button>
                    </Form>
                  </>
                    } 
                  </div>
                </div>
                <div className="card-footer">
                  <div className="clearfix">
                    <div className="float-left">
                      {this.state.step === 1 ? <a className="font-weight-bold bg-special" href="/signIn">BACK TO THE LOG IN</a> : this.state.step === 2 ? <a className="font-weight-bold bg-special" href="#" onClick={() => this.setState({step: 1})}>BACK</a> : null}
                    </div>
                    {
                      this.state.step !== 1 ? <div className="float-right">
                      {this.state.buttonDisabled && <button className="btn btn-primary btn-sm px-3 py-2 radius">NEXT</button>}
                    </div> : null
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
 
export default SignUp;
