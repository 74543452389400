const countries = [
	{
		name: 'Africa',
		abbr: 'af',
		image: 'https://www.goafricaonline.com/images/drapeaux/afrique.png',
	},
	{
		name: 'Algeria',
		abbr: 'dz',
		image: 'https://flagpedia.net/data/flags/h80/dz.png',
	},
	{
		name: 'Angola',
		abbr: 'ao',
		image: 'https://flagpedia.net/data/flags/h80/ao.png',
	},
	{
		name: 'Benin',
		abbr: 'bj',
		image: 'https://flagpedia.net/data/flags/h80/bj.png',
	},

	{
		name: 'Botswana',
		abbr: 'bw',
		image: 'https://flagpedia.net/data/flags/h80/bw.png',
	},
	{
		name: 'Burkina Faso',
		abbr: 'bf',
		image: 'https://flagpedia.net/data/flags/h80/bf.png',
	},
	{
		name: 'Burundi',
		abbr: 'bi',
		image: 'https://flagpedia.net/data/flags/h80/bi.png',
	},

	{
		name: 'Cameroon',
		abbr: 'cm',
		image: 'https://flagpedia.net/data/flags/h80/cm.png',
	},
	{
		name: 'Cape Verde',
		abbr: 'cv',
		image: 'https://flagpedia.net/data/flags/h80/cv.png',
	},
	{
		name: 'Central Afrian Republic',
		abbr: 'cf',
		image: 'https://flagpedia.net/data/flags/h80/cf.png',
	},

	{
		name: 'Chad',
		abbr: 'td',
		image: 'https://flagpedia.net/data/flags/h80/td.png',
	},
	{
		name: 'Comoros',
		abbr: 'km',
		image: 'https://flagpedia.net/data/flags/h80/km.png',
	},
	{
		name: 'Congo',
		abbr: 'cg',
		image: 'https://flagpedia.net/data/flags/h80/cg.png',
	},

	{
		name: 'DR Congo',
		abbr: 'cd',
		image: 'https://flagpedia.net/data/flags/h80/cd.png',
	},
	{
		name: "Cote d'ivoire",
		abbr: 'ci',
		image: 'https://flagpedia.net/data/flags/h80/ci.png',
	},
	{
		name: 'Djibouti',
		abbr: 'dj',
		image: 'https://flagpedia.net/data/flags/h80/dj.png',
	},

	{
		name: 'Egypt',
		abbr: 'eg',
		image: 'https://flagpedia.net/data/flags/h80/eg.png',
	},
	{
		name: 'Equatorial Guinea',
		abbr: 'gq',
		image: 'https://flagpedia.net/data/flags/h80/gq.png',
	},
	{
		name: 'Eritrea',
		abbr: 'er',
		image: 'https://flagpedia.net/data/flags/h80/er.png',
	},

	{
		name: 'Ethiopia',
		abbr: 'et',
		image: 'https://flagpedia.net/data/flags/h80/et.png',
	},
	{
		name: 'Gabon',
		abbr: 'ga',
		image: 'https://flagpedia.net/data/flags/h80/ga.png',
	},
	{
		name: 'Gambia',
		abbr: 'gm',
		image: 'https://flagpedia.net/data/flags/h80/gm.png',
	},

	{
		name: 'Ghana',
		abbr: 'gh',
		image: 'https://flagpedia.net/data/flags/h80/gh.png',
	},
	{
		name: 'Guinea',
		abbr: 'gn',
		image: 'https://flagpedia.net/data/flags/h80/gn.png',
	},
	{
		name: 'Guinea-Bissau',
		abbr: 'gw',
		image: 'https://flagpedia.net/data/flags/h80/gw.png',
	},

	{
		name: 'Kenya',
		abbr: 'ke',
		image: 'https://flagpedia.net/data/flags/h80/ke.png',
	},
	{
		name: 'Lesotho',
		abbr: 'ls',
		image: 'https://flagpedia.net/data/flags/h80/ls.png',
	},
	{
		name: 'Liberia',
		abbr: 'lr',
		image: 'https://flagpedia.net/data/flags/h80/lr.png',
	},

	{
		name: 'Libya',
		abbr: 'ly',
		image: 'https://flagpedia.net/data/flags/h80/ly.png',
	},
	{
		name: 'Madagascar',
		abbr: 'mg',
		image: 'https://flagpedia.net/data/flags/h80/mg.png',
	},
	{
		name: 'Malawi',
		abbr: 'mw',
		image: 'https://flagpedia.net/data/flags/h80/mw.png',
	},

	{
		name: 'Mali',
		abbr: 'ml',
		image: 'https://flagpedia.net/data/flags/h80/ml.png',
	},
	{
		name: 'Mauritania',
		abbr: 'mr',
		image: 'https://flagpedia.net/data/flags/h80/mr.png',
	},
	{
		name: 'Mauritius',
		abbr: 'mu',
		image: 'https://flagpedia.net/data/flags/h80/mu.png',
	},

	{
		name: 'Moroco',
		abbr: 'ma',
		image: 'https://flagpedia.net/data/flags/h80/ma.png',
	},
	{
		name: 'Mozambique',
		abbr: 'mz',
		image: 'https://flagpedia.net/data/flags/h80/mz.png',
	},
	{
		name: 'Namibia',
		abbr: 'na',
		image: 'https://flagpedia.net/data/flags/h80/na.png',
	},

	{
		name: 'Niger',
		abbr: 'ne',
		image: 'https://flagpedia.net/data/flags/h80/ne.png',
	},
	{
		name: 'Nigeria',
		abbr: 'ng',
		image: 'https://flagpedia.net/data/flags/h80/ng.png',
	},
	{
		name: 'Rwanda',
		abbr: 'rw',
		image: 'https://flagpedia.net/data/flags/h80/rw.png',
	},

	{
		name: 'Sao Tome and Principe',
		abbr: 'st',
		image: 'https://flagpedia.net/data/flags/h80/st.png',
	},
	{
		name: 'Senegal',
		abbr: 'sn',
		image: 'https://flagpedia.net/data/flags/h80/sn.png',
	},
	{
		name: 'Seychelles',
		abbr: 'sc',
		image: 'https://flagpedia.net/data/flags/h80/sc.png',
	},

	{
		name: 'Sierra Leone',
		abbr: 'sl',
		image: 'https://flagpedia.net/data/flags/h80/sl.png',
	},
	{
		name: 'Somalia',
		abbr: 'so',
		image: 'https://flagpedia.net/data/flags/h80/so.png',
	},
	{
		name: 'South Africa',
		abbr: 'sa',
		image: 'https://flagpedia.net/data/flags/h80/za.png',
	},

	{
		name: 'South Sudan',
		abbr: 'ss',
		image: 'https://flagpedia.net/data/flags/h80/ss.png',
	},
	{
		name: 'Sudan',
		abbr: 'sd',
		image: 'https://flagpedia.net/data/flags/h80/sd.png',
	},
	{
		name: 'Tanzania',
		abbr: 'tz',
		image: 'https://flagpedia.net/data/flags/h80/tz.png',
	},

	{
		name: 'Togo',
		abbr: 'tg',
		image: 'https://flagpedia.net/data/flags/h80/tg.png',
	},
	{
		name: 'Tunisia',
		abbr: 'tn',
		image: 'https://flagpedia.net/data/flags/h80/tn.png',
	},
	{
		name: 'Uganda',
		abbr: 'ug',
		image: 'https://flagpedia.net/data/flags/h80/ug.png',
	},

	{
		name: 'Western Sahara',
		abbr: 'eh',
		image: 'https://flagpedia.net/data/flags/h80/eh.png',
	},
	{
		name: 'Zambia',
		abbr: 'zm',
		image: 'https://flagpedia.net/data/flags/h80/zm.png',
	},
	{
		name: 'Zimbabwe',
		abbr: 'zw',
		image: 'https://flagpedia.net/data/flags/h80/zw.png',
	},
];

const footerCountries = [
	{
		name: 'Algérie',
		abbr: 'dz',
		image: 'https://cdn.africa.xyz/flags/algeria.png',
	},
	{
		name: 'Angola',
		abbr: 'ao',
		image: 'https://cdn.africa.xyz/flags/angola.png',
	},
	{
		name: 'Benin',
		abbr: 'bj',
		image: 'https://cdn.africa.xyz/flags/benin.png',
	},

	{
		name: 'Botswana',
		abbr: 'bw',
		image: 'https://cdn.africa.xyz/flags/botswana.png',
	},
	{
		name: 'Burkina Faso',
		abbr: 'bf',
		image: 'https://cdn.africa.xyz/flags/burkina-faso.png',
	},
	{
		name: 'Burundi',
		abbr: 'bi',
		image: 'https://cdn.africa.xyz/flags/burundi.png',
	},

	{
		name: 'Cameroon',
		abbr: 'cm',
		image: 'https://cdn.africa.xyz/flags/cameroon.png',
	},
	{
		name: 'Cape Verde',
		abbr: 'cv',
		image: 'https://cdn.africa.xyz/flags/cape-verde.png',
	},
	{
		name: 'Central Afrian Republic',
		abbr: 'cf',
		image: 'https://cdn.africa.xyz/flags/central-african-republic.png',
	},

	{
		name: 'Chad',
		abbr: 'td',
		image: 'https://cdn.africa.xyz/flags/chad.png',
	},
	{
		name: 'Comoros',
		abbr: 'km',
		image: 'https://cdn.africa.xyz/flags/comoros.png',
	},
	{
		name: 'Congo',
		abbr: 'cg',
		image: 'https://cdn.africa.xyz/flags/republic-of-the-congo.png',
	},

	{
		name: 'DR Congo',
		abbr: 'cd',
		image: 'https://cdn.africa.xyz/flags/drc.png',
	},
	{
		name: "Cote d'ivoire",
		abbr: 'ci',
		image: 'https://cdn.africa.xyz/flags/ivory-coast.png',
	},
	{
		name: 'Djibouti',
		abbr: 'dj',
		image: 'https://cdn.africa.xyz/flags/djibouti.png',
	},

	{
		name: 'Egypt',
		abbr: 'eg',
		image: 'https://cdn.africa.xyz/flags/egypt.png',
	},
	{
		name: 'Equatorial Guinea',
		abbr: 'gq',
		image: 'https://cdn.africa.xyz/flags/equatorial-guinea.png',
	},
	{
		name: 'Eritrea',
		abbr: 'er',
		image: 'https://cdn.africa.xyz/flags/eritrea.png',
	},

	{
		name: 'Ethiopia',
		abbr: 'et',
		image: 'https://cdn.africa.xyz/flags/ethiopia.png',
	},
	{
		name: 'Gabon',
		abbr: 'ga',
		image: 'https://cdn.africa.xyz/flags/gabon.png',
	},
	{
		name: 'Gambia',
		abbr: 'gm',
		image: 'https://cdn.africa.xyz/flags/gambia.png',
	},

	{
		name: 'Ghana',
		abbr: 'gh',
		image: 'https://cdn.africa.xyz/flags/ghana.png',
	},
	{
		name: 'Guinea',
		abbr: 'gn',
		image: 'https://cdn.africa.xyz/flags/guinea.png',
	},
	{
		name: 'Guinea-Bissau',
		abbr: 'gw',
		image: 'https://cdn.africa.xyz/flags/guinea-bissau.png',
	},

	{
		name: 'Kenya',
		abbr: 'ke',
		image: 'https://cdn.africa.xyz/flags/kenya.png',
	},
	{
		name: 'Lesotho',
		abbr: 'ls',
		image: 'https://cdn.africa.xyz/flags/lesotho.png',
	},
	{
		name: 'Liberia',
		abbr: 'lr',
		image: 'https://cdn.africa.xyz/flags/liberia.png',
	},

	{
		name: 'Libya',
		abbr: 'ly',
		image: 'https://cdn.africa.xyz/flags/libya.png',
	},
	{
		name: 'Madagascar',
		abbr: 'mg',
		image: 'https://cdn.africa.xyz/flags/madagascar.png',
	},
	{
		name: 'Malawi',
		abbr: 'mw',
		image: 'https://cdn.africa.xyz/flags/malawi.png',
	},

	{
		name: 'Mali',
		abbr: 'ml',
		image: 'https://cdn.africa.xyz/flags/mali.png',
	},
	{
		name: 'Mauritania',
		abbr: 'mr',
		image: 'https://cdn.africa.xyz/flags/mauritania.png',
	},
	{
		name: 'Mauritius',
		abbr: 'mu',
		image: 'https://cdn.africa.xyz/flags/mauritius.png',
	},

	{
		name: 'Moroco',
		abbr: 'ma',
		image: 'https://cdn.africa.xyz/flags/morocco.png',
	},
	{
		name: 'Mozambique',
		abbr: 'mz',
		image: 'https://cdn.africa.xyz/flags/mozambique.png',
	},
	{
		name: 'Namibia',
		abbr: 'na',
		image: 'https://cdn.africa.xyz/flags/namibia.png',
	},

	{
		name: 'Niger',
		abbr: 'ne',
		image: 'https://cdn.africa.xyz/flags/niger.png',
	},
	{
		name: 'Nigeria',
		abbr: 'ng',
		image: 'https://cdn.africa.xyz/flags/nigeria.png',
	},
	{
		name: 'Rwanda',
		abbr: 'rw',
		image: 'https://cdn.africa.xyz/flags/rwanda.png',
	},

	{
		name: 'Sao Tome and Principe',
		abbr: 'st',
		image: 'https://cdn.africa.xyz/flags/sao-tome-and-prince.png',
	},
	{
		name: 'Senegal',
		abbr: 'sn',
		image: 'https://cdn.africa.xyz/flags/senegal.png',
	},
	{
		name: 'Seychelles',
		abbr: 'sc',
		image: 'https://cdn.africa.xyz/flags/seychelles.png',
	},

	{
		name: 'Sierra Leone',
		abbr: 'sl',
		image: 'https://cdn.africa.xyz/flags/sierra-leone.png',
	},
	{
		name: 'Somalia',
		abbr: 'so',
		image: 'https://cdn.africa.xyz/flags/somalia.png',
	},
	{
		name: 'South Africa',
		abbr: 'sa',
		image: 'https://cdn.africa.xyz/flags/south-africa.png',
	},

	{
		name: 'South Sudan',
		abbr: 'ss',
		image: 'https://cdn.africa.xyz/flags/south-sudan.png',
	},
	{
		name: 'Sudan',
		abbr: 'sd',
		image: 'https://cdn.africa.xyz/flags/sudan.png',
	},
	{
		name: 'Tanzania',
		abbr: 'tz',
		image: 'https://cdn.africa.xyz/flags/tanzania.png',
	},

	{
		name: 'Togo',
		abbr: 'tg',
		image: 'https://cdn.africa.xyz/flags/to-go.png',
	},
	{
		name: 'Tunisia',
		abbr: 'tn',
		image: 'https://cdn.africa.xyz/flags/tunisia.png',
	},
	{
		name: 'Uganda',
		abbr: 'ug',
		image: 'https://cdn.africa.xyz/flags/uganda.png',
	},

	// {
	// 	name: 'Western Sahara',
	// 	abbr: 'eh',
	// 	image: 'https://cdn.africa.xyz/flags/eh.png',
	// },
	{
		name: 'Zambia',
		abbr: 'zm',
		image: 'https://cdn.africa.xyz/flags/zam-bia.png',
	},
	{
		name: 'Zimbabwe',
		abbr: 'zw',
		image: 'https://cdn.africa.xyz/flags/zimbabwe.png',
	},
];

export default {
	countries,
	footerCountries,
};
