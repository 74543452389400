import http from './httpService';
import { apiUrl } from '../config/apiUrl';

export function CreateListing(data, phone) {
	const dataJSON = {
		name: data?.name,
		sector: data?.sector,
		country: data.country,
		address: data?.address,
		website: data?.website,
		phone: Object.values(phone),
		description: data?.description,
	};

	return http.post(apiUrl + '/business', dataJSON);
}

export function UpdateListing(user, data, phone) {
	const dataJSON = {
		user_id: user,
		name: data?.name,
		sector: data?.sector,
		address: data?.address,
		website: data?.website,
		phone: Object.values(phone),
		description: data?.description,
		documents: data?.documents,
		country: data.country,
	};

	return http.put(apiUrl + '/listing/view/' + data._id, dataJSON);
}

export function GetListing() {
	return http.get(apiUrl + '/listing/data/view');
}

export function getCountry() {
	return http.get(apiUrl + '/country');
}
