import React from 'react';
import Form from '../common/form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Joi from 'joi-browser';
import { forgetPassword, resetPassword } from '../../../services/authService';
import http from '../../../services/httpService';

class ForgetPassword extends Form {
  state = { 
    loading: false,
    
    data: {
      phone: ''
    },

    loading: false,
    step: 1,
    token: '',
    otp: null,
    otpInput: '',

    password: '',
  }

  schema = {
    phone: Joi.string().required()
  }

  doSubmit = async () => {
    this.setState({loading: true});
    const response = await forgetPassword(this.state.data);
    this.setState({loading: false});

    if(response.data.status) {
      localStorage.setItem('token', response.data.token);
      this.setState({
        token: response.data.token,
        step: this.state.step + 1,
        otp: response.data.OTP
      });
    } else {
      alert('invalid phone number');
    }
  }

  handleNewPassword = async (e) => {
    e.preventDefault();
    http.setJwt(this.state.token)
    this.setState({loading: true});
    const response = await resetPassword({password: this.state.password});
    this.setState({loading: false});
    console.log(response.data);
    window.location.reload();
  }

  handleOTP = (e) => {
    e.preventDefault();

    this.setState({
      loading: true
    });

    if(this.state.otpInput == this.state.otp) {
      setTimeout(() => {
        this.setState({loading: false, step: 3});
      }, 1000)
    } else {
      this.setState({
        loading: false,
        step: 2
      });

      alert('OTP Validation Failed');
    }
  }

  render() { 
    return (  
      <div className="container px-5">
        <h5 className="font-weight-bold text-muted my-5">Forget Password</h5>
        <form className="mx-5 px-5" onSubmit={this.state.step === 1 ? this.handleSubmit : this.state.step === 2 ? this.handleOTP : this.handleNewPassword}>
          {
            this.state.step === 1 ? 
            <PhoneInput
              country={'rw'}
              regions={'africa'}
              inputClass="form-control border-0 w-100"
              buttonClass="border-0"
              containerClass="border-0 p-0 mb-4"
              placeholder="(999) 999 999 999"
              value={this.state.data.phone}
              onChange={phone => this.setState({
                data: {phone: '+' + phone}
              })}
              countryCodeEditable={false}
            /> : this.state.step === 2 ? 
            <input 
              name="otp" 
              required={true} 
              autoComplete={false} 
              disabled={this.state.loading} 
              value={this.state.otpInput} 
              placeholder="******"
              onChange={e => this.setState({ otpInput: e.target.value })} 
              className="form-control mb-4"
            /> : 
            <input 
              name="passwordNew" 
              type="password"
              required={true} 
              autoComplete={false} 
              value={this.state.password} 
              placeholder="New Password"
              onChange={e => this.setState({ password: e.target.value })} 
              className="form-control mb-4"
            /> 
          }
          <button className="btn btn-primary btn-sm px-3 py-2 mb-5">{this.state.loading ? 'Loading....' : <span>{this.state.step === 1 ? 'Forgot Password' : this.state.step === 2 ? 'Validate Otp' : 'Reset Password'}</span>}</button>
        </form>
          
      </div>
    );
  }
}
 
export default ForgetPassword;
