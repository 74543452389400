import React from 'react';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';

import './index.css';
import { getCurrentUser } from '../../services/authService';

import { useLocation } from 'react-router-dom';

import helpers from '../../utils/helpers';

export default class AdminLayout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			sideBarActive: props.sidebar ?? true,
			user: {},
		};
	}

	async componentDidMount() {
		if (localStorage.getItem('token')) {
			window.$crisp = [];
			window.CRISP_WEBSITE_ID = 'eb4f7b0d-e188-4b88-9fa0-2fa15ffd7207';

			var d = document;
			var s = d.createElement('script');

			s.src = 'https://client.crisp.chat/l.js';
			s.async = 1;
			d.getElementsByTagName('head')[0].appendChild(s);
		}

		const { data } = await getCurrentUser();

		this.setState({
			user: data.data,
		});
	}

	render() {
		const { user } = this.state;

		return (
			<>
				<div
					className={
						this.state.sideBarActive === true
							? 'wrapper'
							: 'wrapper sidebar-mini'
					}
				>
					<aside className='sidebar color-white'>
						<div className='sidebar-header text-center'>
							<a href='/' className='font-weight-bold text-white'>
								Africa XYZ
							</a>
						</div>

						<ul className='list-unstyled components'>
							<p className='text-small text-bold'>MAIN NAVIGATION</p>
							<li>
								<Link
									to='/dashboard'
									className={helpers.activeMenu('/dashboard')}
								>
									<i className='fas fa-tachometer-alt'></i> Dashboard
								</Link>
							</li>

							<li>
								<Link
									to='/dashboard/profile/sms'
									className={helpers.activeMenu('/dashboard/profile/sms')}
								>
									<i className='fas fa-sign-out-alt'></i> Direct SMS
								</Link>
							</li>
							{/* <div className='accordion' id='accordionExample'>
								<div id='headingOne'>
									<ul>
										<li>
											<a
												className='pb-4 pt-3'
												href='#collapseOne'
												data-toggle='collapse'
											>
												<span className='float-left mb-3'>
													<i className='fa fa-credit-card' /> E-merchant Card
												</span>
												<small className='float-right mb-3'>
													<i className='fa fa-chevron-right' />
												</small>
											</a>
										</li>
									</ul>
								</div>
								<ul
									id='collapseOne'
									className='bg-dark collapse'
									aria-labelledby='headingOne'
									data-parent='#accordionExample'
								>
									<li>
										<Link
											to='/dashboard/profile/generate'
											className={helpers.activeMenu(
												'/dashboard/profile/generate'
											)}
										>
											<i className='fa fa-link'></i> Generate link
										</Link>
										<Link
											to='/dashboard/profile/transaction'
											className={helpers.activeMenu(
												'/dashboard/profile/transaction'
											)}
										>
											<i className='fa fa-history'></i> Transaction
										</Link>
									</li>
								</ul>
							</div> */}
							<li>
								<Link
									to='/dashboard/profile'
									className={helpers.activeMenu('/dashboard/profile')}
								>
									<i className='fas fa-user'></i> Profile
								</Link>
							</li>

							<li>
								<Link to='/signOut'>
									<i className='fas fa-sign-out-alt'></i> Sign Out
								</Link>
							</li>
						</ul>
					</aside>

					<div className='content bg-primary color-black'>
						<nav className='navbar navbar-expand-lg admin-navbar bg-info'>
							<div className='container-fluid'>
								<Button
									variant='link'
									onClick={() =>
										this.setState({ sideBarActive: !this.state.sideBarActive })
									}
								>
									<i
										className='fas fa-bars'
										style={{ fontSize: '12px !important', color: '#ffffff' }}
									></i>
								</Button>
								<h6 className='font-weight-bold mt-2'>
									<a href='/' className='text-white ml-2'>
										Home
									</a>
								</h6>
								<ul className='nav navbar-nav ml-auto'>
									<li className='nav-item'>
										<span className='nav-link'>
											<i className='fa fa-user'></i>
											<span className='user-name ml-2'>
												{user?.email || user?.name}
											</span>
										</span>
									</li>
								</ul>
							</div>
						</nav>

						<div className='box' style={{ background: '#f8f8f8' }}>
							<Container fluid>
								<Row>
									<Col lg={12}>
										<div className='box-body pl-3 pr-3 pt-md-4 pb-4 pt-2'>
											{this.props.children}
										</div>
									</Col>
								</Row>
							</Container>
							<div className='box-footer py-4 bg-white'>
								<Container fluid className='pr-4 pl-4'>
									<Row>
										<Col lg={12}>
											<span className='color-black ml-2'>
												&copy; 2021 Africa XYZ All Rights Reserved
											</span>
										</Col>
									</Row>
								</Container>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
