import React from 'react';
import { Link } from 'react-router-dom';
import { list } from '../../../services/businessService';
import FilterComponent from './filterComponent';
import Layout from './layout';
import queryString from 'query-string';

import { lang } from '../../../utils/trans';

import './index.css';

export default class SearchCompanyView extends React.Component {
	constructor(props) {
		super(props);

		// this.handlePagination = this.handlePagination.bind(this);

		this.state = {
			loading: true,
			country: this.props.match.params.country,
			filterOptions: {
				q: queryString.parse(this.props.location.search).q ?? null,
				w: queryString.parse(this.props.location.search).w ?? null,
			},
			list: [],
			page: {
				total: 0,
				current: 0,
				last: 1,
			},

			optionActive: {
				value: queryString.parse(this.props.location.search).q ?? '',
				label:
					queryString.parse(this.props.location.search).q ??
					lang('Who, What ?'),
			},
			countryActive: {
				value: queryString.parse(this.props.location.search).w ?? '',
				label: queryString.parse(this.props.location.search).w ?? lang('Where'),
			},
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);

		this.setState({ loading: true });

		let params = {};

		const page = queryString.parse(this.props.location.search).page;
		const q = queryString.parse(this.props.location.search).q;
		const w = queryString.parse(this.props.location.search).w;

		if (page) {
			params = { ...params, skip: page };
		}

		if (q) {
			params = { ...params, q: q };
		}

		if (w) {
			params = { ...params, w: w };
		}

		if (this.state.country !== 'africa') {
			params = { ...params, c: this.state.country };
		}

		const listResponse = await list(params);

		this.setState({
			loading: false,
			list: listResponse.data.data,
			page: {
				total: listResponse.data.pagination.total,
				current: listResponse.data.pagination.current,
				last: listResponse.data.pagination.last,
			},
		});
	}

	async handlePagination(type) {
		const current =
			type === 1 ? this.state.page.current - 1 : this.state.page.current + 1;

		let url =
			window.location.protocol +
			'//' +
			window.location.host +
			window.location.pathname;

		const params = queryString.parse(this.props.location.search);
		delete params.page;

		let urlParams = '';

		Object.keys(params).map((key, data) =>
			key === '' ? '' : (urlParams = urlParams + '&' + key + '=' + data)
		);

		if (params.length > 0) {
			urlParams = '?page=' + current + urlParams;
		} else {
			urlParams = '?page=' + current;
		}

		url = url + urlParams;

		window.location.href = url;
	}

	render() {
		return (
			<Layout country={this.state.country} theme={true}>
				<FilterComponent
					country={this.state.country}
					optionActive={this.state.optionActive}
					countryActive={this.state.countryActive}
				/>

				{this.state.loading ? (
					<main className='saerch-are bg-white py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-12'>
									<h4>
										<div
											class='spinner-border text-primary'
											role='status'
										></div>{' '}
										Loading...
									</h4>
								</div>
							</div>
						</div>
					</main>
				) : (
					<main className='saerch-are bg-white py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-12'>
									<p>
										Home | Your research {this.state.page.total} results found
									</p>
								</div>
							</div>

							<div className='row mt-3'>
								{this.state.list
									.filter(data => data.phone.length > 0)
									.map((data, index) => (
										<div
											key={index}
											className='col-lg-7 col-md-4 col-sm-6 mb-3 border'
										>
											<Link to={'/' + this.state.country + '/' + data?.slug}>
												<div className='list-box'>
													<div className='card-body'>
														<h6 className='font-bold color-black text-title'>
															{data?.name}
														</h6>
														<div className='media text-muted'>
															<div className='media-body'>
																<p className='text-info color-blue'>
																	{data?.sector}
																</p>
															</div>
														</div>
														<div className='text-muted'>
															<div className='row'>
																<p className='col-md-4'>
																	<i className='fa fa-map-marker mt-1'></i>{' '}
																	{data?.address}
																</p>

																<p className='ml-3'>
																	<i className='fa fa-phone mt-1'></i> &nbsp;
																	{Object.keys(data.phone).map((key, value) =>
																		data.phone[key] === 'N/A' ? (
																			''
																		) : (
																			<span key={key} className='mr-2'>
																				{data.phone[key]}
																			</span>
																		)
																	)}
																</p>
															</div>
														</div>
														<div className='row'>
															<div className='col-3'>
																<div className='media text-muted'>
																	<big>
																		<i class='far fa-file'></i>
																	</big>
																	<div className='media-body pl-1'>
																		<big>Profile</big>
																	</div>
																</div>
															</div>
															<div className='col'>
																<div className='media text-muted'>
																	<big>
																		<i class='fas fa-globe'></i>
																	</big>
																	<div className='media-body pl-1'>
																		<big className='text-muted'> website </big>
																	</div>
																</div>
															</div>
														</div>
														<p className='text-dark mt-3'>
															{data.description !== null &&
															data.description !== ''
																? data.description.length > 200
																	? data.description.substring(0, 200) + ' ...'
																	: data.description.substring(0, 200)
																: ''}
														</p>
													</div>
												</div>
											</Link>
										</div>
									))}
							</div>

							{this.state.page.last > '1' ? (
								<>
									<div className='row mt-4'>
										<div className='col-lg-12'>
											<p>
												Showing {this.state.page.current + 1} /{' '}
												{this.state.page.last} pages
											</p>
										</div>
									</div>

									<div className='row mt-3'>
										<div className='col-lg-12'>
											<button
												className='btn btn-default btn-paggination border py-2 mr-2'
												onClick={() => this.handlePagination(1)}
												disabled={this.state.page.current <= '0' ? true : false}
											>
												<i className='fas fa-caret-left'></i>
											</button>

											<button
												className='btn btn=default btn-paggination border py-2'
												onClick={() => this.handlePagination(2)}
												disabled={
													this.state.page.current + 1 === this.state.page.last
														? true
														: false
												}
											>
												<i className='fas fa-caret-right'></i>
											</button>
										</div>
									</div>
								</>
							) : null}
						</div>
					</main>
				)}
			</Layout>
		);
	}
}
