import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import RouteConfig from '../../../config/routes';
import Account from '../../../assets/images/icon/account.svg';
import { reverse } from 'named-urls';

import './index.css';

const DATA = [
	{
		name: 'Afrique',
		abbr: '',
		image: 'https://www.goafricaonline.com/images/drapeaux/afrique.png',
	},
	{
		name: 'Afrique du Sud',
		abbr: 'za',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/za.png',
	},
	{
		name: 'Algerie',
		abbr: 'dz',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/dz.png',
	},
	{
		name: 'Angola',
		abbr: 'ao',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/ao.png',
	},
	{
		name: 'Benin',
		abbr: 'bj',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/bj.png',
	},
	{
		name: 'Botswana',
		abbr: 'bw',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/bw.png',
	},
	{
		name: 'Burkina Faso',
		abbr: 'bf',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/bf.png',
	},
	{
		name: 'Cameroun',
		abbr: 'cm',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/cm.png',
	},
	{
		name: 'Congo-Brazaville',
		abbr: 'cg',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/cg.png',
	},
	{
		name: 'Congo-Kinshasa',
		abbr: 'cd',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/cd.png',
	},
	{
		name: "Cote d'Ivoire",
		abbr: 'ci',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/ci.png',
	},
	{
		name: 'Djibouti',
		abbr: 'dj',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/dj.png',
	},
	{
		name: 'Egypte',
		abbr: 'eg',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/eg.png',
	},
	{
		name: 'Ethiopie',
		abbr: 'et',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/et.png',
	},
	{
		name: 'Gabon',
		abbr: 'ga',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/ga.png',
	},
	{
		name: 'Ghana',
		abbr: 'gh',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/gh.png',
	},
	{
		name: 'Guinee',
		abbr: 'gn',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/gn.png',
	},
	{
		name: 'Kenya',
		abbr: 'ke',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/ke.png',
	},
	{
		name: 'Liberia',
		abbr: 'lr',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/lr.png',
	},
	{
		name: 'Madagascar',
		abbr: 'mg',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/mg.png',
	},
	{
		name: 'Malawi',
		abbr: 'mw',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/mw.png',
	},
	{
		name: 'Mali',
		abbr: 'ml',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/ml.png',
	},
	{
		name: 'Maroc',
		abbr: 'ma',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/ma.png',
	},
	{
		name: 'Maurice',
		abbr: 'mu',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/mu.png',
	},
	{
		name: 'Mozambique',
		abbr: 'mz',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/mz.png',
	},
	{
		name: 'Namibie',
		abbr: 'na',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/na.png',
	},
	{
		name: 'Niger',
		abbr: 'ne',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/na.png',
	},
	{
		name: 'Nigeria',
		abbr: 'ng',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/ne.png',
	},
	{
		name: 'Ouganda',
		abbr: 'ug',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/ng.png',
	},
	{
		name: 'Republique Centrafricaine',
		abbr: 'cf',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/ug.png',
	},
	{
		name: 'Senegale',
		abbr: 'sn',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/na.png',
	},
	{
		name: 'Somalie',
		abbr: 'so',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/so.png',
	},
	{
		name: 'Tanzania',
		abbr: 'tz',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/tz.png',
	},
	{
		name: 'Togo',
		abbr: 'tg',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/tg.png',
	},
	{
		name: 'Tunisie',
		abbr: 'tn',
		image:
			'https://www.goafricaonline.com/build/9.4.0/images/flags/shiny/64/tn.png',
	},
];

export const Navbar = props => {
	const [user, setUser] = useState('');
	const [data, setData] = useState(DATA);
	const [navbar, setNavbar] = useState(props.menu ?? false);

	const changeBackground = () => {
		if (window.scrollY >= 50) return setNavbar(true);

		return setNavbar(props.menu ?? false);
	};

	window.addEventListener('scroll', changeBackground);

	return (
		<>
			<nav
				className={
					navbar
						? 'navbar navbar-expand-lg navbar-light fixed-top active shadow'
						: 'navbar navbar-expand-lg fixed-top navbar-light'
				}
			>
				<div className='container-fluid'>
					<Link
						to='/'
						className={
							navbar ? 'navbar-brand text-black-50' : 'navbar-brand text-white'
						}
						href='#'
					>
						<i className='fa fa-business-time'></i> Africa XYZ
					</Link>

					<button
						className='navbar-toggler text-white'
						type='button'
						data-toggle='collapse'
						data-target='#navbarNav'
						aria-controls='navbarNav'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<i
							className={
								navbar ? 'fa fa-bars text-black-50' : 'fa fa-bars text-white'
							}
						></i>
					</button>

					<div className='collapse navbar-collapse' id='navbarNav'>
						<ul className='navbar-nav ml-auto'>
							<li className='nav-item active'>
								<Link
									to='/'
									className={navbar ? 'nav-link-scroll' : 'nav-link'}
									href='#'
								>
									Home{' '}
								</Link>
							</li>
							<li className='nav-item'>
								<a
									className={navbar ? 'nav-link-scroll' : 'nav-link'}
									href='#countries'
									data-toggle='modal'
								>
									Countries <i className='fa fa-chevron-down'></i>
								</a>
							</li>
							{/* <li className='nav-item'>
								<Link
									to='/'
									className={navbar ? 'nav-link-scroll' : 'nav-link'}
									href='#'
								>
									Explore
								</Link>
							</li> */}
							<li className='nav-item'>
								<Link
									to='/signin'
									className={navbar ? 'nav-link-scroll' : 'nav-link'}
								>
									<i className='fa fa-user'></i>&nbsp; Sign in
								</Link>
							</li>
							<li className='nav-item my-1 mb-2'>
								<button
									data-toggle='modal'
									data-target='#business'
									className={
										navbar
											? 'btn btn-outline-dark btn-sm mx-3'
											: 'btn btn-outline-light btn-sm py-1 ml-3'
									}
								>
									<small>
										<i className='fa fa-plus'></i> &nbsp; Add Business
									</small>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<div className='modal fade bd-example-modal-lg' id='countries'>
				<div className='modal-dialog modal-lg'>
					<div className='modal-content'>
						<div className='modal-body'>
							<div className='row text-center'>
								{data.map(d => (
									<div key={d.abbr} className='col-md col-sm-3 col-4'>
										<a
											href={reverse(RouteConfig.country.filter, {
												country: d.name.toLowerCase(),
											})}
										>
											<img
												src={d.image}
												className='img-fluid'
												width='50px'
												height='50px'
												alt=''
											/>
											<p className='text-muted'>{d.name}</p>
										</a>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='modal fade' id='business'>
				<div className='modal-dialog modal-lg'>
					{!user && (
						<div className='modal-content modal-body text-center'>
							<h6 className='text-muted font-weight-bold my-4'>
								WELCOME TO AFRICAONLINE
							</h6>
							<p className='text-muted'>
								We can't let you create a bussiness without an account?
							</p>
							<div className='d-flex justify-content-center mt-3'>
								<img src={Account} className='img-fluid w-25 mb-4' />
							</div>
							<div className='col-8 offset-2 my-3'>
								<a href='/signUp' className='btn btn-primary btn-sm px-4 py-1'>
									<small>Sign Up</small>
								</a>
							</div>
							<a className='text-muted' href='/'>
								<u>return to home</u>
							</a>
						</div>
					)}
					{user && (
						<div className='modal-content'>
							<div className='bg-dark mt-4'>
								<div className='container py-4'>
									<h3 className='font-weight-bold text-white mb-3'>
										Register Your Bussiness
									</h3>
									<p className='text-white'>
										Please provide all required details to register your
										bussiness with us
									</p>
								</div>
							</div>
							<div className='modal-body'>
								<div className='row'>
									<div className='col-md-4 my-1'>
										<p className='mt-2 text-muted'>Business Owner</p>
									</div>
									<div className='col-md-8 my-1'>
										<div className='row'>
											<div className='col-md-6 my-1'>
												<input
													type='text'
													className='form-control'
													placeholder='First name'
												/>
											</div>
											<div className='col-md-6 my-1'>
												<input
													type='text'
													className='form-control'
													placeholder='Second name'
												/>
											</div>
										</div>
									</div>
									<div className='col-md-4 my-1'>
										<p className='mt-2 text-muted'>Business Name</p>
									</div>
									<div className='col-md-8 my-1'>
										<input
											type='text'
											className='form-control'
											placeholder='Business name'
										/>
									</div>
									<div className='col-md-4 my-1'>
										<p className='mt-2 text-muted'>Contact Number</p>
									</div>
									<div className='col-md-8 my-1'>
										<div className='row'>
											<div className='col-md-6 my-1'>
												<input
													type='text'
													className='form-control'
													placeholder='Area Code'
												/>
											</div>
											<div className='col-md-6 my-1'>
												<input
													type='text'
													className='form-control'
													placeholder='Phone number'
												/>
											</div>
										</div>
									</div>
									<div className='col-md-4 my-1'>
										<p className='mt-2 text-muted'>Email</p>
									</div>
									<div className='col-md-8 my-1'>
										<input
											type='email'
											className='form-control'
											placeholder='johnDoe@gmail.com'
										/>
									</div>
									<div className='col-md-4'>
										<p className='mt-3 text-muted'>Address</p>
									</div>
									<div className='col-md-8'>
										<input
											type='text'
											className='form-control my-3'
											placeholder='Adress 1'
										/>
										<input
											type='text'
											className='form-control my-3'
											placeholder='Adress 2'
										/>
										<div className='row'>
											<div className='col-md-6'>
												<input
													type='text'
													className='form-control my-2'
													placeholder='City'
												/>
											</div>
											<div className='col-md-6'>
												<input
													type='text'
													className='form-control my-2'
													placeholder='State / Province'
												/>
											</div>
											<div className='col-md-6'>
												<input
													type='text'
													className='form-control my-2'
													placeholder='Postcode'
												/>
											</div>
											<div className='col-md-6'>
												<select className='custom-select my-2' defaultValue='0'>
													<option value='0'>Rwanda</option>
													<option value='1'>Malawi</option>
													<option value='2'>Rwanda</option>
													<option value='3'>Cameroon</option>
													<option value='2'>Ouganda</option>
													<option value='3'>Niger</option>
													<option value='2'>Nigeria</option>
													<option value='3'>South Afrique</option>
												</select>
											</div>
										</div>
									</div>
									<div className='col-md-4 mt-3'>
										<p className='mt-2 text-muted'>Bussiness Type: </p>
									</div>
									<div className='col-md-8 mt-3'>
										<select className='custom-select' defaultValue='0'>
											<option value='0'>Company</option>
											<option value='1'>Shopping</option>
											<option value='2'>Cosmetics</option>
											<option value='3'>Restaurant</option>
											<option value='2'>Hotel</option>
											<option value='3'>Agencies</option>
										</select>
									</div>
									<div className='col-md-4'>
										<p className='mt-2 text-muted'>Message: </p>
									</div>
									<div className='col-md-8'>
										<textarea
											className='form-control'
											name='message'
											placeholder='Message'
											rows='5'
										></textarea>
									</div>
								</div>
								<div className='text-center mt-4'>
									<button className='btn btn-primary'>
										<i className='fa fa-paper-plane'></i> &nbsp; Submit
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
