import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import './index.css';

export class TestimonyContainer extends React.Component {
	render() {
		return (
			<div className='testimony-container background-white py-5'>
				<Container>
					<Row>
						<Col lg={5} className='columns mb-4'>
							<Image
								src='https://classic.listingprowp.com/wp-content/uploads/2016/12/video-img-7.jpg'
								className='img-fluid'
								alt='Claim & Get Started Today!'
							/>
						</Col>
						<Col lg={6} className='columns'>
							<p className='color-black text-sub-title font-medium mb-2'>
								People talking about us
							</p>
							<p className='color-black text-small font-light message-box background-grey px-4 py-4 mt-4'>
								We have exceeded our `{`goals for`}` how many people are going
								to our website, staying on our website and also calling us…
								We’ve always viewed our relationship with listingpro as an
								investment to keep us current in our community and to keep us
								relevant and our brand out there.
							</p>

							<div className='testimony mt-5'>
								<div className='user-thumb'>
									<Image
										src='https://classic.listingprowp.com/wp-content/uploads/2016/12/happy_client-63x63-1.jpg'
										className='img-fluid'
										alt='Testimony'
									/>
								</div>
								<div className='test-descr'>
									<p className='mb-1 color-grey text-small font-light'>
										Richard Jeans
									</p>
									<p className='color-grey text-small font-light'>
										DX Media. Cortland, OH
									</p>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
