import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import AdminLayout from '../../layouts/AdminLayout';
import axios from 'axios';
import { GetListing, getSMS } from '../../../services/businessService';
import { getCurrentUser } from '../../../services/authService';
import moment from 'moment';

class SmsMain extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sms: {
				count: {},
				history: [],
			},
		};
	}

	async componentDidMount() {
		const sms = await getSMS();

		this.setState({
			sms: sms.data.data,
		});
	}

	render() {
		return (
			<AdminLayout>
				<div className='clearfix'>
					<div className='float-left'>
						<h5 className='font-weight-bold text-muted'>Direct SMS</h5>
					</div>
				</div>

				<div className='clearfix mt-md-4'>
					<div className='row'>
						<Col xl={3} sm={6} className='my-md-2 my-1'>
							<div className='sms-box shadow bg-primary p-md-5 p-2 text-white'>
								<h5>
									<span>{this.state.sms?.count?.remaining}</span>
									<i className='fas fa-angle-left float-right'></i>
								</h5>

								<h6 className='mt-md-4'>SMS Remaining Today</h6>
							</div>
						</Col>

						<Col xl={3} sm={6} className='my-md-2 my-1'>
							<div className='sms-box shadow bg-info p-md-5 p-3 text-white'>
								<h5>
									<span>{this.state.sms?.count?.sent}</span>
									<i className='fas fa-paper-plane float-right'></i>
								</h5>

								<h6 className='mt-md-4'>SMS Sent Today</h6>
							</div>
						</Col>

						<Col xl={3} sm={6} className='my-md-2 my-1'>
							<div className='sms-box shadow bg-success p-md-5 p-3 text-white'>
								<h5>
									<span>{this.state.sms?.count?.succeed}</span>
									<i className='fas fa-thumbs-up float-right'></i>
								</h5>

								<h6 className='mt-md-4'>SMS Succeeded Today</h6>
							</div>
						</Col>

						<Col xl={3} sm={6} className='my-md-2 my-1'>
							<div className='sms-box shadow bg-danger p-md-5 p-3 text-white'>
								<h5>
									<span>{this.state.sms?.count?.failed}</span>
									<i className='fas fa-thumbs-down float-right'></i>
								</h5>

								<h6 className='mt-md-4'>SMS Failed Today</h6>
							</div>
						</Col>
					</div>
				</div>

				<div className='d-none d-md-block table-responsive mt-md-5 mt-2'>
					<table className='table'>
						<thead>
							<tr>
								<th scope='col'>#</th>
								<th scope='col'>Receipent</th>
								<th scope='col' style={{ width: '30%' }}>
									Message
								</th>
								<th scope='col'>Date / Time</th>
								<th scope='col'></th>
							</tr>
						</thead>
						<tbody>
							{this.state.sms.history.map((data, key) => (
								<tr>
									<td>{key + 1}</td>
									<td>{data.to}</td>
									<td>{data.message}</td>
									<td>{moment(data.created_at).format('MMM Do YYYY')}</td>
									<td>
										{data.status === 1 ? (
											<button className='btn btn-secondary btn-circle btn-sm'>
												<i className='fas fa-spinner'></i>
											</button>
										) : null}

										{data.status === 2 ? (
											<button className='btn btn-primary btn-circle btn-sm'>
												<i className='fas fa-check'></i>
											</button>
										) : null}

										{data.status === 3 ? (
											<button className='btn btn-danger btn-circle btn-sm'>
												<i className='fas fa-times'></i>
											</button>
										) : null}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className='d-block d-md-none mt-3'>
					{this.state.sms.history.map(data => (
						<div className='card border-0 mb-2'>
							<div className='card-body pb-2'>
								<div className='clearfix'>
									<div className='float-left'>
										<h6>
											<small className='font-weight-bold text-muted'>
												{data.to}
											</small>
										</h6>
									</div>
									<div className='float-right'>
										<strong className='text-muted'>{data.date}</strong>
									</div>
								</div>

								<p className='text-muted'>
									<small>{data.message}</small>
								</p>
							</div>
						</div>
					))}
				</div>
			</AdminLayout>
		);
	}
}

export default SmsMain;
