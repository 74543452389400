import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Karibu from '../../../../assets/images/banner/karibu-Africa-Online.jpg'

import { lang } from '../../../../utils/trans';

const Testimonial = () => {
  return (
    <div className='testimony-container background-white py-5'>
      <Container>
        <Row>
          <Col lg={5} className='columns mb-4'>
            <Image
              src={Karibu}
              className='img-fluid'
              alt='Claim & Get Started Today!'
            />
          </Col>
          <Col lg={6} className='columns'>
            <p className='color-black text-sub-title font-medium mb-2'>
             {lang('Our Values and Mission')}
            </p>
            <p className='color-black text-small font-light message-box background-grey px-4 py-4 mt-4'>
              {lang('We are A pan-African multinational company headquatered in Rwanda. We build a digital Marketplace that allows entrepreneurs to sell their products and services across Africa and receive payments with the E-merchant Card.')}
            </p>

            <div className='testimony mt-5'>
              <div className='user-thumb'>
                <Image
                  src={Karibu}
                  className='img-fluid'
                  alt='Testimony'
                />
              </div>
              <div className='test-descr'>
                <p className='mb-1 color-grey text-small font-light'>
                  Africa XYZ | {lang('Team')}
                </p>
                <p className='color-grey text-small font-light'>
                  Kigali, Rwanda
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
 
export default Testimonial;