import React, { Component } from 'react';
import {
	deleteLink,
	generateLink,
	getGeneratedLink,
	businessData,
} from '../../../../services/emerchant';
import NumberFormat from 'react-number-format';
import { Badge } from 'react-bootstrap';
import numWords from 'num-words';
import AdminLayout from '../../../layouts/AdminLayout';

class DashboardLinkView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			copyId: null,
			data: [],
			product: {
				currency: 'RWF',
				name: '',
				price: '',
				description: '',
			},
			show: true,
			addLink: false,
		};

		this.handleCopyLink = this.handleCopyLink.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handlePrice = this.handlePrice.bind(this);
		this.createLink = this.createLink.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	handleCopyLink(id, link) {
		this.setState({
			copyId: id,
		});

		var textField = document.createElement('textarea');

		textField.innerText =
			process.env.REACT_APP_E_MERCHANT_PAYMENT + '/payment/' + link;

		document.body.appendChild(textField);
		textField.select();

		document.execCommand('copy');
		textField.remove();
	}

	handlePrice(input) {
		let product = { ...this.state.product };
		product['price'] = input['value'];
		this.setState({ product });
	}

	handleChange(e) {
		const product = { ...this.state.product };
		product[e.target.name] = e.target.value;
		this.setState({ product });
	}

	handleDelete = async id => {
		try {
			await deleteLink(id);
			window.location.reload();
		} catch (error) {
			console.log('Error ', error);
		}
	};

	async createLink(e) {
		e.preventDefault();
		try {
			await generateLink({ product: this.state.product });
			window.location.reload();
		} catch (ex) {
			console.log('Error', ex);
		}
	}

	async componentDidMount() {
		const response = await getGeneratedLink();
		const request = await (await businessData()).data.data;

		if (request && request?.company) {
			if ('name' in request.company && 'location' in request.company) {
				// enable user to create link
				this.setState({ addLink: true });
			} else {
				this.setState({ addLink: false });
			}
		} else {
			this.setState({ addLink: false });
		}

		this.setState({ data: response.data.data });
	}

	render() {
		return (
			<AdminLayout>
				{this.state.show && (
					<div className='alert alert-danger clearfix border-0'>
						<div className='float-left'>
							<div className='media'>
								<strong
									className='font-weight-bold bg-danger px-2 text-white'
									style={{ borderRadius: '50%' }}
								>
									!
								</strong>
								<div className='media-body pl-2'>
									This is for the testing purpose only.
								</div>
							</div>
						</div>
						<div className='float-right'>
							<button
								className='btn btn-sm py-0'
								onClick={() => this.setState({ show: false })}
							>
								<i className='fas fa-times' />
							</button>
						</div>
					</div>
				)}
				<div className='clearfix'>
					<div className='float-left'>
						<h5 className='font-weight-bold text-muted'>Generate Link</h5>
					</div>
					<div className='float-right'>
						<button
							className='btn btn-primary btn-sm px-2 py-1 mx-1'
							data-toggle='modal'
							data-target='#generate'
						>
							<i className='fa fa-plus pt-1'></i> &nbsp;
							<small> Generate New</small>
						</button>
					</div>
				</div>
				<div className='d-none d-md-block table-responsive mt-5'>
					<table className='table'>
						<thead>
							<tr>
								<th scope='col'>#</th>
								<th scope='col'>Product Name</th>
								<th scope='col'>Product Price</th>
								<th scope='col'>Link</th>
								<th scope='col'>Date</th>
								<th scope='col'></th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map((key, value) => (
								<tr>
									<th scope='row'>{key._id}</th>
									<td>{key.product.name}</td>
									<td>
										{key.product.price} <strong>{key.product.currency}</strong>
									</td>
									<td>
										<Badge
											variant='secondary'
											className='p-2'
											style={{ background: '#f9f9f9', color: '#000000' }}
										>
											{process.env.REACT_APP_E_MERCHANT_PAYMENT +
												'/payment/' +
												key._id}
											<Badge
												variant={
													this.state.copyId === key ? 'warning' : 'primary'
												}
												className='ml-1 p-2'
												style={{ cursor: 'pointer' }}
												onClick={() => this.handleCopyLink(key, key._id)}
											>
												{this.state.copyId === key ? 'Copied' : 'Copy'}
											</Badge>
										</Badge>
									</td>
									<td>
										<small className='font-weight-bold text-muted'>
											{key.date}
										</small>
									</td>
									<td>
										{/* <small className="btn btn-danger mx-1 py-1 px-2 font-weight-bold small" onClick={() => this.handleDelete(key._id)}>x</small> */}
										{/* <small className="btn btn-info mx-1 px-2 small"><i className="fa fa-edit" /></small> */}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="d-block d-md-none mt-3">
					<div className="card border-0">
						<div className="card-body pb-2">
							<div className="clearfix">
								<div className="float-left"><h6><small className="font-weight-bold text-muted">Maraphone</small></h6></div>
								<div className="float-right">
									<strong className="text-muted">22-01-2021</strong>
								</div>
							</div>
							<small>
								{/* <Badge
									variant='secondary'
									className='p-2'
									style={{ background: '#f9f9f9', color: '#000000' }}
								>
									{process.env.REACT_APP_E_MERCHANT_PAYMENT +
										'/payment/' +
										key._id
									}
									<Badge
										variant={
											this.state.copyId === key ? 'warning' : 'primary'
										}
										className='ml-1 p-2'
										style={{ cursor: 'pointer' }}
										onClick={() => this.handleCopyLink(key, key._id)}
									>
										{this.state.copyId === key ? 'Copied' : 'Copy'}
									</Badge>
								</Badge> */}
							</small>
							<h6><small className="font-weight-bold">Rwf 12,000</small></h6>
						</div>
					</div>
				</div>
				<div
					className='modal fade'
					id='generate'
					aria-labelledby='exampleModalLabel'
					aria-hidden='true'
				>
					<div className='modal-dialog'>
						<div className='modal-content'>
							<div className='modal-body'>
								<h6 className='font-weight-bold text-muted mb-4'>
									Generate payment
								</h6>
								{!this.state.addLink ? (
									'Not eligable to generate payment link! please fill your company information'
								) : (
									<form onSubmit={this.createLink}>
										<div className='form-group'>
											<label className='text-muted'>Names:</label>
											<input
												type='text'
												name='name'
												onChange={this.handleChange}
												value={this.state.product.name}
												placeholder='Names'
												className='form-control'
											/>
										</div>
										<div className='form-group'>
											<label className='text-muted'>Price:</label>
											<div className='input-group mb-3'>
												<div className='input-group-prepend'>
													<select
														className='form-control m-0 border-right-0'
														id='inputGroupSelect01'
														name='currency'
														onChange={this.handleChange}
													>
														<option value='RWF'>RWF</option>
														<option value='USD'>USD</option>
														<option value='EUR'>EUR</option>
													</select>
												</div>
												<NumberFormat
													className='form-control border-left-0 mt-0'
													placeholder='Price'
													value={this.state.product.price}
													onValueChange={this.handlePrice}
													thousandSeparator={true}
												/>
											</div>
										</div>
										{/* <span className="pb-4">The Amount: <strong className="text-success">{numWords(this.state.product.price)}</strong></span> */}
										<div className='form-group mt-4'>
											<label className='text-muted'>Description:</label>
											<textarea
												rows='5'
												name='description'
												onChange={this.handleChange}
												value={this.state.product.description}
												className='form-control'
												placeholder='Description'
											/>
										</div>

										<button className='btn btn-primary btn-sm px-3 py-2'>
											<small> Create </small>
										</button>
									</form>
								)}
							</div>
						</div>
					</div>
				</div>
			</AdminLayout>
		);
	}
}

export default DashboardLinkView;
