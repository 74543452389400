import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ClaimImage from '../../../../assets/images/banner/claim.png';

import { lang } from '../../../../utils/trans';

const Claim = () => {
		return (
			<div className='claim-container background-grey'>
				<Container>
					<Row>
						<Col lg={12}>
							<h2 className='color-black text-center text-title font-medium'>
								{lang('Get Started Today!')}
							</h2>
							<p className='text-center color-grey text-normal'>
								{lang('Want to start selling your products & services in more than 45 countries in Africa ?')}
							</p>
						</Col>
					</Row>

					<Row className='mt-5'>
						<Col lg={6} className='columns mb-4'>
							<Image
								src={ClaimImage}
								className='img-fluid'
								alt='Claim & Get Started Today!'
							/>
						</Col>
						<Col lg={5} className='columns'>
							<p className='color-black text-normal font-medium mb-2'>
								1- {lang('Register')}
							</p>
							<p className='color-grey text-small font-light'>
								{lang('Best way to start managing your business listing is by creating an account and adding your informations.')}
							</p>

							<p className='mt-5 color-black text-normal font-medium mb-2'>
								2- {lang('Promote')}
							</p>
							<p className='color-grey text-small font-light'>
								{lang('Promote your business to target customers who need your services or products.')}
							</p>

							<p className='mt-5 color-black text-normal font-medium mb-2'>
								3- {lang('Convert')}
							</p>
							<p className='color-grey text-small font-light'>
								{lang('Turn your prospects into paying customers by receiving your payments with the E-merchant Card.')}
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		);
}
 
export default Claim;
