import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';

export class CitiesContainer extends React.Component {
	render() {
		return (
			<div className='cities-container py-5 mb-5 mt-5'>
				<Container>
					<Row>
						<Col lg={12}>
							<h2 className='color-black text-center text-title font-medium'>
								Happening Cities
							</h2>
							<p className='text-center color-grey text-small'>
								Cities You Must Explore This Summer
							</p>
						</Col>
					</Row>

					<Row className='mt-5'>
						<Col lg={5} className='mb-4'>
							<Link className='city-box'>
								<Image
									src='https://classic.listingprowp.com/wp-content/uploads/2017/01/8680800001_02ed76abd8_z-570x455.jpg'
									className='img-fluid latest'
									alt='city Image'
								/>

								<div className='inner'>
									<p className='text-center color-white mb-1 text-normal'>
										Chicago
									</p>
									<p className='text-center color-white text-small'>
										5 Listings
									</p>
								</div>
							</Link>
						</Col>

						<Col lg={6}>
							<Row>
								<Col lg={12}>
									<Link className='city-box'>
										<Image
											src='https://classic.listingprowp.com/wp-content/uploads/2017/01/losanglese-570x228.jpg'
											className='img-fluid flat'
											alt='city Image'
										/>

										<div className='inner'>
											<p className='text-center color-white mb-1 text-normal'>
												San Francisco
											</p>
											<p className='text-center color-white text-small'>
												5 Listings
											</p>
										</div>
									</Link>
								</Col>
							</Row>

							<Row className='mt-4'>
								<Col lg={6} className='mb-4'>
									<Link className='city-box'>
										<Image
											src='https://classic.listingprowp.com/wp-content/uploads/2017/01/newyork-270x197.jpeg'
											className='img-fluid small'
											alt='city Image'
										/>

										<div className='inner'>
											<p className='text-center color-white mb-1 text-normal'>
												Los Angelas
											</p>
											<p className='text-center color-white text-small'>
												5 Listings
											</p>
										</div>
									</Link>
								</Col>

								<Col lg={6}>
									<Link className='city-box'>
										<Image
											src='https://classic.listingprowp.com/wp-content/uploads/2017/01/800px-Cable_car_on_California_Street-270x197.jpg'
											className='img-fluid small'
											alt='city Image'
										/>

										<div className='inner'>
											<p className='text-center color-white mb-1 text-normal'>
												New York
											</p>
											<p className='text-center color-white text-small'>
												5 Listings
											</p>
										</div>
									</Link>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
