import React from 'react';

import Comb from '../../../assets/images/icon/comb.svg';
import Fork from '../../../assets/images/icon/fork.svg';
import Office from '../../../assets/images/icon/office-building.svg';
import Shopping from '../../../assets/images/icon/shopping-bag.svg';
import Travel from '../../../assets/images/icon/travel-agency.svg';

import './index.css';

export class ServiceBox extends React.Component {
	render() {
		return (
			<section className='services__section'>
				<div className='container px-md-5'>
					<div className='row mx-md-5 px-md-5 text-center'>
						<div className='col-6 col-md my-2'>
							<div className='card border-0'>
								<div className='card-body'>
									<p className='font-weight-bold text-muted'>Companies</p>
									<img src={Office} className='img-fluid' alt='' />
								</div>
							</div>
						</div>
						<div className='col-6 col-md my-2'>
							<div className='card border-0'>
								<div className='card-body'>
									<p className='font-weight-bold text-muted'>Agencies</p>
									<img src={Travel} className='img-fluid' alt='' />
								</div>
							</div>
						</div>
						<div className='col-6 col-md my-2'>
							<div className='card border-0'>
								<div className='card-body'>
									<p className='font-weight-bold text-muted'>Restaurant</p>
									<img src={Fork} className='img-fluid' alt='' />
								</div>
							</div>
						</div>
						<div className='col-6 col-md my-2'>
							<div className='card border-0'>
								<div className='card-body'>
									<p className='font-weight-bold text-muted'>Beauty</p>
									<img src={Comb} className='img-fluid' alt='' />
								</div>
							</div>
						</div>
						<div className='col-6 col-md my-2'>
							<div className='card border-0'>
								<div className='card-body'>
									<p className='font-weight-bold text-muted'>Shopping</p>
									<img src={Shopping} className='img-fluid' alt='' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
