import React from 'react';
import { Link } from 'react-router-dom';
import Tour from '../../../assets/images/icon/tour.svg';
import Joi from 'joi-browser';
import Form from '../common/form';
import { login } from '../../../services/authService';
import Logo from '../../../assets/images/banner/africa.xyz Logo.png';
import Mobile from '../../../assets/images/banner/smart_sms.svg';
import English from '../../../assets/images/banner/usa.png';
import French from '../../../assets/images/banner/france.png';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ForgetPassword from './forgetPassword';

class SignIn extends Form {
  state = {
    redirect: new URLSearchParams(this.props.location.search).get("redirect"),
    page: new URLSearchParams(this.props.location.search).get("page"),
    data: {phone: '', password: ''},
    errors: {},
    isPasswordShown: false,
    forget: false,
    loading: false,
  }

  schema = {
    password: Joi.string().required().label('Password'),
  }
  
  togglePasswordVisibility = () => {
    const {isPasswordShown} = this.state;
    this.setState({isPasswordShown: !isPasswordShown});
  }

  handleRedirect = () => {
		window.location.href='/';
	}
  
  doSubmit = async (e) => {

    e.preventDefault()
    try {
      await login(this.state.data);     
      if(this.state.redirect) {
        window.location = '/' + localStorage.getItem('country') + '/' + this.state.page;
      } else {
        window.location = '/dashboard';
      }

    } catch (ex) {
      if(ex.response && ex.response?.status === 400 || ex.response?.status === 401) {
        const errors = { ...this.state.errors };
        errors.phone = ex.response.data.detail;
        this.setState({ errors });
      }
    }
  }

  render() {
    const {isPasswordShown} = this.state;
    return (
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex flex-column justify-content-between" style={{height: '100vh'}}>
                <img src={Logo} className='navbar-brand img-fluid w-50' onClick={this.handleRedirect} />
                {!this.state.forget && <div className="container m-md-5">
                  <div className="row">
                    <div className="col-md-8 offset-md-2 text-center">
                      <div className="my-md-5">
                        <h5 className="font-weight-bold mb-3 text-center">Login to Africa XYZ</h5>
                        {this.state.errors.phone && <p className="alert alert-danger font-weight-bold">{this.state.errors.phone}</p>}
                        <form onSubmit={this.doSubmit} encType="multipart/form-data">
                          <PhoneInput
                            country={'rw'}
                            regions={'africa'}
                            inputClass="form-control border-0 w-100"
                            buttonClass="border-0"
                            containerClass="p-0 mb-4"
                            placeholder="(999) 999 999 999"
                            value={this.state.data.phone}
                            onChange={(phone) => {this.setState({
                              data: {...this.state.data, phone: phone}
                            })}}
                            countryCodeEditable={false}
                          />

                          {/* <TextField size="small" value={data.phone} name="email" label="Email" onChange={this.handlechange} className="my-3" fullWidth variant="outlined" /> */}


                          <div className="input-group mb-3">
                            <input type={isPasswordShown ? "text" : "password"} name="password" className="form-control border-0" placeholder="********" required={true} autoComplete={false} onChange={e => this.setState({
                              data: {...this.state.data, password: e.target.value}
                            })} value={this.state.data.password} />
                            <div className="input-group-append">
                              <i className={`fas ${isPasswordShown ? "fa-eye-slash" : "fa-eye"} input-group-text text-muted border-0 bg-white mt-1 cursor`} onClick={this.togglePasswordVisibility} />
                            </div>
                          </div>
                          
                          <div className="float-left mt-2 mb-3 ml-4">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label className="form-check-label pt-1" for="defaultCheck1">
                              Stay logged in permanently
                            </label>
                          </div>
                          <a className="float-right cursor text-muted mt-2 mb-3" onClick={() => this.setState({forget: true})}><small className="font-weight-bold text-primary"> Lost Password ? </small></a>
                          <div className="mt-4">
                            <button type="submit" disabled={this.state.loading} className="btn btn-primary btn-sm btn-block py-2" style={{color: '#fff'}}>
                              {this.state.loading ? 'Loading' : 'LOG IN'}
                            </button>
                          </div>
                        </form>
                        <div className="mt-3">
                          <Link to="/signUp" className="btn btn-outline-danger btn-sm btn-block py-2">CREATE NEW ACCOUNT</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
                {this.state.forget && <div className="text-center mb-5 pb-5">
                  <ForgetPassword />
                  <a className="text-dark mt-2 pb-5 cursor" onClick={() => this.setState({forget: false})}>Return to Sign In </a>
                </div>}
                <div className="dropdown mb-md-5 mb-3">
                  <button className="btn btn-outline-secondary btn-sm" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="media">
                      <img src={English} className="img-fluid mt-1" width="16" height="16" />
                      <div className="media-body pl-2">
                        <small>English (en) <i className="fa fa-caret-down pl-3" /></small>
                      </div>
                    </div>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <button className="dropdown-item" type="button">
                      <div className="media">
                        <img src={French} className="img-fluid mt-1" width="16" height="16" />
                        <div className="media-body pl-2">
                          <small>French (fr) <i className="fa fa-caret-down pl-3" /></small>
                        </div>
                      </div>
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 m-0 p-0 d-md-block d-none">
              <div className="bg_image_signIn px-md-5">
                <div className="d-flex align-items-center justify-content-center flex-column" style={{ height: '100vh'}}>
                  <div className="card mb-4">
                    <div className="card-body py-2">
                      <img src={Mobile} className="img-fluid w-100" alt=""/>
                    </div>
                  </div>
                  <h6 className="font-weight-bolder mb-3 text-white"><strong>Direct SMS</strong></h6>
                  <p className="text-white text-center px-md-4">Promote your products, sales, and events better with Smart SMS.It combines the standard SMS message with the beautiful design and content of your Africa XYZ Business page.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
 
export default SignIn;