import http from './httpService';
import { apiUrl, newApi } from '../config/apiUrl';

export function filter() {
	return http.get(apiUrl + '/business/options');
}

export function sendCompany(company) {
	return http.put(newApi + '/user/data', company);
}

export function list(params) {
	console.log(params);
	return http.get(apiUrl + '/business/search', {
		params: params,
	});
}

export function getBusiness() {
	return http.get(apiUrl + '/business/user');
}

export function CreateListing(data, phone) {
	// const dataJSON = {
	// 	name: data.name,
	// 	sector: data.sector,
	// 	country: data.country,
	// 	address: data.address,
	// 	phone: phone,
	// 	website: data.website,
	// 	description: data.description,
	// };

	console.log(data);

	// return http.post(apiUrl + '/business', dataJSON);
}
export function UpdateListing(data, id) {
	return http.put(apiUrl + '/listing/' + id, data);
}
export function GetListing(slug) {
	return http.get(apiUrl + '/business/view/' + slug);
}

export function sendSMS(data) {
	return http.post(apiUrl + '/message', data);
}

export function getSMS() {
	return http.get(apiUrl + '/business/sms');
}
