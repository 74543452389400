import { useEffect } from 'react';
import { logout } from '../../../services/authService';

const SignOut = () => {
  useEffect(() => {
    logout();

    window.location = '/';
  }, []);

  return null;
}
 
export default SignOut;