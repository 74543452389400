import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ClaimImage from '../../../assets/images/banner/claim.png';

import './index.css';

export class ClaimContainer extends React.Component {
	render() {
		return (
			<div className='claim-container background-grey'>
				<Container>
					<Row>
						<Col lg={12}>
							<h2 className='color-black text-center text-title font-medium'>
								Claim & Get Started Today!
							</h2>
							<p className='text-center color-grey text-normal'>
								Nearly 80% Of Consumers Turn To Directories With Reviews To Find
								A Local Business.
							</p>
						</Col>
					</Row>

					<Row className='mt-5'>
						<Col lg={6} className='columns mb-4'>
							<Image
								src={ClaimImage}
								className='img-fluid'
								alt='Claim & Get Started Today!'
							/>
						</Col>
						<Col lg={5} className='columns'>
							<p className='color-black text-normal font-medium mb-2'>
								1- Claim
							</p>
							<p className='color-grey text-small font-light'>
								Best way to start managing your business listing is by claiming
								it so you can update.
							</p>

							<p className='mt-5 color-black text-normal font-medium mb-2'>
								2- Promote
							</p>
							<p className='color-grey text-small font-light'>
								Promote your business to target customers who need your services
								or products.
							</p>

							<p className='mt-5 color-black text-normal font-medium mb-2'>
								3- Convert
							</p>
							<p className='color-grey text-small font-light'>
								Turn your visitors into paying customers with exciting offers
								and services on your page.
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
