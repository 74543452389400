import { include } from 'named-urls';

const RouteConfig = {
	home: '/',
	signin: '/signIn',
	signup: '/signUp',
	signout: '/signOut',
	country: include('/c/:country', {
		filter: '',
		view: ':slug',
	}),
	dashboard: include('/dashboard', {
		profile: '/profile',
	}),
};

export default RouteConfig;
