import React from 'react';
import { Link } from 'react-router-dom';

import Comb from '../../../../assets/images/banner/mobile money 1.png';
import Fork from '../../../../assets/images/banner/mastercard-icon 1.png';
import Office from '../../../../assets/images/banner/paypal-icon.png';
import Shopping from '../../../../assets/images/banner/bank_transfer-h-229-line 1.png';
import Travel from '../../../../assets/images/banner/visa-card-icon 1.png';

const Service = (props) => {
  return (
    <section className='services__section'>
      <div className='container px-md-5'>
        <div className='row mx-md-5 px-md-5 text-center'>
          <div className='col-6 col-md my-2'>
            <a href="https://emc.xyz/login" target="_blank" className='card border-0'>
              <div className='card-body'>
                <p className='font-weight-bold text-muted'>Accept Paypal Payments</p>
                <img src={Office} className='img-fluid' alt='' />
              </div>
            </a>
          </div>
          <a href="https://emc.xyz/login" target="_blank" className='col-6 col-md my-2'>
            <div className='card border-0'>
              <div className='card-body'>
                <p className='font-weight-bold text-muted'>Accept VISA Cards</p>
                <img src={Travel} className='img-fluid' alt='' />
              </div>
            </div>
          </a>
          <div className='col-6 col-md my-2'>
            <a href="https://emc.xyz/login" target="_blank" className='card border-0'>
              <div className='card-body'>
                <p className='font-weight-bold text-muted'>Accept Master Card</p>
                <img src={Fork} className='img-fluid' alt='' />
              </div>
            </a>
          </div>
          <a href="https://emc.xyz/login" target="_blank" className='col-6 col-md my-2'>
            <div className='card border-0'>
              <div className='card-body'>
                <p className='font-weight-bold text-muted'>Accept Mobile Money</p>
                <img src={Comb} className='img-fluid' alt='' />
              </div>
            </div>
          </a>
          <div className='col-6 col-md my-2'>
            <a href="https://emc.xyz/login" target="_blank" className='card border-0'>
              <div className='card-body'>
                <p className='font-weight-bold text-muted'>Accept Wire Transfer</p>
                <img src={Shopping} className='img-fluid' alt='' />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
 
export default Service;
