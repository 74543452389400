import React from 'react'
import Footer from './footer'
import Navbar from './navbar'

export default class Layout extends React.Component {
  constructor(props) {
		super(props);

		this.state = {
			country: props.country
		}
	}

	componentDidMount() {
		localStorage.setItem('country', this.state.country);
	}

  render() {
    return(
      <>
        <Navbar country={this.props.country} theme={this.props.theme} />
        <div>{this.props.children}</div>
        <Footer />
      </>
    );
  }
}
