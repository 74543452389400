import http from './httpService';
import { apiUrl } from '../config/apiUrl';
import jwtDecode from 'jwt-decode';

http.setJwt(getJwt());

export async function login(auth) {
	const { data } = await http.post(apiUrl + '/auth/token', auth);

	localStorage.setItem('token', data.data);

	return true;
}

export function logout() {
	return localStorage.clear();
}

export function resetPassword(password) {
	return http.put(apiUrl + '/auth', password);
}

export function forgetPassword(phone) {
	return http.put(apiUrl + '/auth/forgot', phone);
}

export function getCurrentUser() {
	return http.get(apiUrl + '/auth/user');
}

export function getJwt() {
	return localStorage.getItem('token');
}

export function register(data) {
	return http.post(apiUrl + '/auth/signup', data);
}
