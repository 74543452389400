import React from 'react';
import { Row, Col, Container, Image, Card } from 'react-bootstrap';
import countries from '../../company/listCountries';
import AdminLayout from '../../../layouts/AdminLayout';

import SMSBanner from '../../../../assets/images/banner/register_mobile.png';
import SMSBanner2 from '../../../../assets/images/banner/Login-sms.png';
import { Link } from 'react-router-dom';
import { getCountry } from '../../../../services/listing';
import './index.css';

export default class DashboardView extends React.Component {

  state = {
    country: ''
  }

  async componentDidMount() {
    const {data} = await getCountry();
    this.setState({country: data.country_available});
    console.log(this.state.country)
  }

	render() {
		return (
			<AdminLayout>
				<div className='clearfix'>
					<h5 className='text-capitalize text-title float-left d-none d-md-block'>Dashboard</h5>
					<h6 className='text-capitalize text-title float-left d-block d-md-none my-0 pb-2'><small className="font-weight-bold"> Dashboard</small></h6>
				</div>

          <Row className="bg-white border-top border-primary mt-md-3 p-md-4 mt-md-4">
            <Col md={7}>
              <h5 className="text-capitalize text-sub-title mb-md-4 d-none d-md-block">Countries (Stats)</h5>
              <h6 className="text-capitalize text-sub-title mt-3 d-block d-md-none"><small className="font-weight-bold"> Countries (Stats)</small></h6>
              <div className="d-none d-md-block">
                <Row>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/tanzania'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/tanzania.png" className='img-fluid' width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0"><small>Tanzania</small></h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <p className="py-0 pt-2"><small><strong>2560</strong> Companies</small></p>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/kenya'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/kenya.png" className='img-fluid 25' width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0"><small>Kenya</small></h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <p className="py-0 pt-2"><small><strong>1340</strong> Companies</small></p>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/senegal'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/senegal.png" className='img-fluid' width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0"><small>Senegal</small></h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <p className="py-0 pt-2"><small><strong>19940</strong> Companies</small></p>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/benin'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/benin.png" className='img-fluid' width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0"><small>Benin</small></h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <p className="py-0 pt-2"><small><strong>16380</strong> Companies</small></p>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/moroco'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/morocco.png" className='img-fluid' width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0"><small>Moroco</small></h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <p className="py-0 pt-2"><small><strong>3510</strong> Companies</small></p>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/south%20africa'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/south-africa.png" className='img-fluid' width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0"><small>South Africa</small></h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <p className="py-0 pt-2"><small><strong>2920</strong> Companies</small></p>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* {
                    Object.values(this.state.country).slice(1, 7).map(data => 
                      <Col md={6} className='mb-2'>
                        <Card className="shadow border-0">
                          <Card.Body className="px-2 py-1">
                            <a className="mb-0" href={'/' + data.name.charAt(0).toLowerCase() + '' + data.name.slice(1)}>
                              <div className="clearfix">
                                <div className="float-left">
                                  <div className="media">
                                  <Image src={data.image} className='img-fluid' width="50" alt={data.name} />
                                  <div className="media-body pl-2 pt-2">
                                    <p className="py-0"><small>{data.name}</small></p>
                                  </div>
                                </div>
                                </div>
                                <div className="float-right">
                                  <p className="py-0 pt-2"><small>{data.total} <strong>Companies</strong></small></p>
                                </div>
                              </div>
                              
                            </a>
                          </Card.Body>
                        </Card>
                      </Col> */}
                      {/* // <Col md={6} className='mb-md-3 mb-2'>
                      //   <Card className="shadow border-0">
                      //     <Card.Body className="p-md-2 px-2 py-1">
                      //       <a className="mb-0" href={'/' + data.name.charAt(0).toLowerCase() + '' + data.name.slice(1)}>
                      //         <div className="media">
                      //           <Image src={data.image} className='img-fluid w-25' alt={data.name} />
                      //           <div className="media-body pl-5">
                      //             <p className="py-0 my-0 text-center">{data.name}</p>
                      //             <p className="py-0 my-0 text-center">{data.total}</p>
                      //             <p className="py-0 my-0 text-center"><strong>Companies</strong></p>
                      //           </div>
                      //         </div>
                      //       </a>
                      //     </Card.Body>
                      //   </Card>
                      // </Col> */}
                      {/* )
                  } */}
                </Row>
              </div>
              <div className="d-block d-md-none">
                <Row>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/tanzania'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/tanzania.png" className='img-fluid'  width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0">Tanzania</h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <h6 className="py-0 pt-2"><strong>2560</strong> Companies</h6>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/kenya'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/kenya.png" className='img-fluid'  width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0">Kenya</h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <h6 className="py-0 pt-2"><strong>1340</strong> Companies</h6>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/senegal'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/senegal.png" className='img-fluid'  width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0">Senegal</h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <h6 className="py-0 pt-2"><strong>19940</strong> Companies</h6>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} className='mb-2'>
                    <Card className="shadow border-0">
                      <Card.Body className="p-2">
                        <a className="mb-0 text-muted" href='/benin'>
                          <div className="clearfix">
                            <div className="float-left">
                              <div className="media">
                              <Image src="https://cdn.africa.xyz/flags/benin.png" className='img-fluid' width="35" />
                              <div className="media-body pl-2 pt-2">
                                <h6 className="py-0">Benin</h6>
                              </div>
                            </div>
                            </div>
                            <div className="float-right">
                              <h6 className="py-0 pt-2"><strong>16380</strong> Companies</h6>
                            </div>
                          </div>
                          
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* {
                    Object.values(this.state.country).slice(1, 5).map(data => 
                      <Col md={6} className='mb-2'>
                        <Card className="shadow border-0">
                          <Card.Body className="px-2 py-1">
                            <a className="mb-0" href={'/' + data.name.charAt(0).toLowerCase() + '' + data.name.slice(1)}>
                              <div className="clearfix">
                                <div className="float-left">
                                  <div className="media">
                                  <Image src={data.image} className='img-fluid' width="50" alt={data.name} />
                                  <div className="media-body pl-2">
                                    <p className="py-0">{data.name}</p>
                                  </div>
                                </div>
                                </div>
                                <div className="float-right">
                                  <p className="py-0">{data.total} <strong>Companies</strong></p>
                                </div>
                              </div>
                              
                            </a>
                          </Card.Body>
                        </Card>
                      </Col>
                      )
                  } */}
                </Row>
              </div>
            </Col>
            <Col md={5}>
              <h5 className="text-capitalize text-sub-title mb-md-4 d-none d-md-block py-0 my-0">Direct SMS</h5>
              <h6 className="text-capitalize text-sub-title mb-md-4 d-block d-md-none py-0 my-0"><small className="font-weight-bold"> Direct SMS</small></h6>
              <Card className="shadow border-0 card-height">
                <Card.Body className="p-md-3 p-1">
                  <Row>
                    <Col md={7} className="mx-0 px-0">
                      <img src={SMSBanner} className="img-fluid d-none d-md-inline-block mx-0 px-0 mx-0" alt="" />
                      <img src={SMSBanner2} className="img-fluid d-block d-md-none mx-0 px-0 mx-0" alt="" />
                    </Col>
                    <Col md={5} className="mx-0 px-0 pt-2">
                      <p className="text-muted d-none d-md-block">Start Promoting your company by sending Direct SMS.</p>
                      <h6 className="text-muted d-block d-md-none mt-2 mx-2">Start Promoting your company by sending Direct SMS.</h6>
                      <h6><small className="font-weight-bold d-none d-md-block">Want to Start ?</small></h6>
                      <h5 className="font-weight-bold text-muted mt-3 d-block d-md-none mx-2">Want to Start ?</h5>
                      <p className="text-muted p-0 mx-md-0 mx-2 d-none d-md-block"> Click <a href="/dashboard/profile">here</a> to complete your profile.</p>
                      <h6 className="text-muted p-0  mx-md-0 mx-2 d-block d-md-none"> Click <a href="/dashboard/profile">here</a> to complete your profile.</h6>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              
              {/* <Card className="shadow border-0 mt-4">
                <Card.Body>
                  <h2 className="font-weight-bold text-center d-none d-md-block">Fill your Infos</h2>
                  <h6 className="font-weight-bold text-center d-block d-md-none">Fill your Infos</h6>
                </Card.Body>
              </Card>
              <h6 className="text-center pt-4">
                <Link to="/dashboard/profile">Want to Start ?</Link>
              </h6> */}
            </Col>
          </Row>
			</AdminLayout>
		);
	}
}
