import React from 'react';

const Input = ({ error, ...rest}) => {
  return (
    <>
      <div className="form-group">
        <input {...rest} className="form-control my-3 py-3" autoComplete='off' />
      </div>
      {error && <div className="text-danger font-weight-bold mb-3">{error}</div>}
   </>
        
  );
}

export default Input;